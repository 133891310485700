import { makeStyles } from "@mui/styles";

export const UserCommonStyles = makeStyles((theme) => {
  return {
    //common width and height style set -------------
    w100: {
      width: "100%",
    },
    w50: {
      width: "50% !important",
    },
    w150: {
      width: "150px !important",
    },

    h100: {
      height: "100%",
    },

    // Font Bold Set ----------

    weightLight: {
      fontWeight: "400 !important",
    },

    bold1: {
      fontWeight: "500 !important",
    },
    bold2: {
      fontWeight: "700 !important",
    },

    //common spacing style set ---------------
    m0: {
      margin: "0 !important",
    },

    p0: {
      padding: 0,
    },

    py20: {
      padding: "3px 20px !important",
    },

    py10: {
      padding: "15px !important",
    },

    borderRad15: {
      borderRadius: "15px !important",
    },

    pt0: {
      paddingTop: "0 !important",
    },

    ms20: {
      marginLeft: "20px !important",
    },

    p20: {
      padding: "20px",
    },

    pb20: {
      paddingBottom: "20px",
    },
    pb12: {
      paddingBottom: "12px",
    },

    // pt12: {
    //   paddingTop: "0px !important",
    // },
    pt20: {
      paddingTop: "20px !important",
    },

    px20: {
      padding: "0 30px !important",
    },
    px15: {
      padding: "0 15px !important",
    },

    px10: {
      padding: "0 10px !important",
    },

    py5: {
      padding: "5px 0 !important",
    },
    py15: {
      padding: "15px 0 !important",
    },

    btnpy1060: {
      padding: "10px 60px !important",
    },

    pb8: {
      paddingBottom: "8px",
    },

    mt5: {
      marginTop: "15px !important",
    },
    mt10: {
      marginTop: "10px !important",
    },
    mt15: {
      marginTop: "15px !important",
    },
    mt20: {
      marginTop: "20px !important",
    },
    mt30: {
      marginTop: "30px !important",
    },

    mt40: {
      marginTop: "40px !important",
    },

    mb10: {
      marginBottom: "10px !important",
    },

    m5: {
      margin: "5px",
    },

    mt84: {
      marginTop: "84px",
    },

    mb20: {
      marginBottom: "20px !important",
    },

    mb50: {
      marginBottom: "50px",
    },

    mb15: {
      marginBottom: "15px !important",
    },

    mr5: {
      marginRight: "5px",
    },

    ml5: {
      marginLeft: "5px",
    },

    mr10: {
      marginRight: "10px !important",
    },

    mr20: {
      marginRight: "20px",
    },
    mr30: {
      marginRight: "30px !important",
    },

    ml10: {
      marginLeft: "10px !important",
    },
    ml4: {
      marginLeft: "5px !important",
    },

    ml20: {
      marginLeft: "20px",
    },

    m20: {
      margin: "20px",
    },

    my10: {
      margin: "10px 0",
    },
    my15: {
      margin: "15px 0",
    },

    //font size set --------------
    font12: {
      fontSize: "12px !important",
    },

    font14: {
      fontSize: "14px !important",
    },

    font16: {
      fontSize: "16px !important",
    },

    font18: {
      fontSize: "18px !important",
    },
    font20: {
      fontSize: "20px !important",
    },
    font22: {
      fontSize: "22px !important",
    },
    font24: {
      fontSize: "24px !important",
    },

    font30: {
      fontSize: "30px !important",
    },
    font48: {
      fontSize: "48px !important",
    },

    fontWeight600: {
      fontWeight: 600,
    },

    fontWeight800: {
      fontWeight: 800,
    },

    fontWeight300: {
      fontWeight: 300,
    },
    fontWeight400: {
      fontWeight: 400,
    },

    fontWeight500: {
      fontWeight: 500,
    },

    //anchor descoration set ------------
    anchorDecoration: {
      textDecoration: "none",
    },

    //cursor pointer style -------------
    cursorA: {
      cursor: "pointer",
    },

    //alignment style set --------------

    textCenter: {
      textAlign: "center",
    },

    textRight: {
      textAlign: "right",
    },

    notificationBadgeStyle: {
      display: "inline-block",
      position: "absolute",
      background: "#D95557",
      color: "white",
      padding: "1px 5px",
      borderRadius: "50%",
      fontSize: "13px",
      fontWeight: "light",
    },

    cartBadgeContainer: {
      width: "20px",
      height: "20px",
      borderRadius: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      backgroundColor: "#D95557",
      top: 20,
      right: 15,
    },

    cartBadgeStyle: {
      color: "white",
      fontSize: "12px",
      fontWeight: "light",
      margin: "0px !important",
      padding: "0px !important",
    },
    // cart icon hover ----------------
    hoverTrigger: {
      "&:hover img": {
        filter: "brightness(0) invert(1)",
      },
    },
    //common flex style set ---------------
    dflex: {
      display: "flex",
    },

    flexColumn: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexFlow: "column",
    },

    flexEnd: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },

    alignItemsCenter: {
      alignItems: "center !important",
    },

    flexCenter: {
      display: "flex",
      alignItems: "center",
    },

    justifySpaceBetween: {
      justifyContent: "space-between",
    },
    justifySpaceAround: {
      justifyContent: "space-around",
    },

    justifyCenter: {
      justifyContent: "center",
    },

    justifyEnd: {
      justifyContent: "flex-end",
    },

    //common border none set -------------
    borderBottom: {
      borderBottom: "1px solid",
    },
    borderRad8: {
      borderRadius: "8px !important",
    },

    borderTop: {
      borderTop: "1px solid",
    },

    //common input style set --------------
    commonInput: {
      width: "100%",
      marginBottom: "15px",
      position: "relative",
      "& input": {
        backgroundColor: "#fff",
        borderRadius: "12px",
        width: "100%",
      },
      "& textarea": {
        backgroundColor: "#fff",
        borderRadius: "12px",
        width: "100%",
      },
    },

    labelCommonInput: {
      color: "#4C4C4C",
      fontSize: "14px !important",
      marginBottom: "5px !important",
    },

    uploadBoxOutline: {
      border: "1px solid #707070",
      borderRadius: "4px",
      overflow: "hidden",
      position: "relative",
      height: "54px",
    },

    overflowAuto: {
      overflow: "auto",
    },

    uploadBtn: {
      height: "100%",
      backgroundColor: "#01B4BC",
      color: "#fff",
      position: "absolute",
      top: 0,
      right: 0,
      width: "150px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& div": {
        height: "100%",
        position: "absolute",
        top: 0,
        "&::before": {
          display: "none",
        },
        "&::after": {
          display: "none",
        },
      },
      "& input": {
        height: "100%",
        opacity: 0,
      },
    },

    settingsBtn: {
      "& button": {
        backgroundColor: "#01b4bc",
        color: "#fff",
        width: "40px",
        height: "40px",
        "&:hover": {
          backgroundColor: "#01b4bc",
        },
      },
    },

    //common error and success msg set -------------
    errorMsg: {
      color: "#ff0000",
      fontSize: "12px",
    },

    //common color style set -------------
    orange: {
      color: "#D95557",
    },

    skyBlue: {
      color: "#01B4BC !important",
    },

    blueShade: {
      color: "#D3D8E0 !important",
    },

    white: {
      color: "#fff !important",
    },

    grey: {
      color: "#3E4347",
    },
    black: {
      color: "#000000",
    },
    tundora: {
      color: "#4C4C4C",
    },
    dusty_Gray: {
      color: "#969696",
    },
    silver_Chalice: {
      color: "#A0A0A0",
    },

    bggrey: {
      backgroundColor: "#3E4347",
    },

    lightGrey: {
      color: "#707070",
    },

    bglightGrey: {
      backgroundColor: "#999999",
    },

    yellow: {
      color: "#FAB854 !important",
    },

    red: {
      color: "#ff848a !important",
    },

    blue: {
      color: "#2430ff !important",
    },

    bgWhite: {
      backgroundColor: "#fff",
    },

    bgSkyBlue: {
      backgroundColor: "#01B4BC !important",
    },

    bgRed: {
      backgroundColor: "#DA5557 !important",
    },

    bgBlack: {
      backgroundColor: "#000 !important",
    },

    bgBlue: {
      backgroundColor: "#3B5998 !important",
    },

    //list style remove set --------------
    removeListStyle: {
      listStyle: "none",
      margin: 0,
      padding: 0,
    },

    //common button style set -------------
    orangeBtn: {
      "& button": {
        backgroundColor: "#D95557",
        borderRadius: "10px",
        color: "#fff",
        border: "1px solid #D95557",
        textTransform: "capitalize",
        padding: "8px 30px",
        fontSize: "16px",
        fontWeight: 300,
        whiteSpace: "nowrap",
        "&:hover": {
          backgroundColor: "#fff",
          color: "#D95557",
        },
      },
    },

    orangeOutlineBtn: {
      "& button": {
        backgroundColor: "#fff",
        borderRadius: "10px",
        border: "1px solid #D95557",
        color: "#D95557",
        textTransform: "capitalize",
        padding: "8px 30px",
        fontSize: "16px",
        fontWeight: 300,
        whiteSpace: "nowrap",
        "&:hover": {
          backgroundColor: "#D95557",
          color: "#fff",
        },
      },
    },

    blueOutlineBtn: {
      "& button": {
        backgroundColor: "#fff",
        borderRadius: "10px",
        border: "1px solid #01B4BC",
        color: "#01B4BC",
        textTransform: "capitalize",
        padding: "8px 30px",
        fontSize: "16px",
        fontWeight: 300,
        whiteSpace: "nowrap",
        // "&:hover": {
        //   backgroundColor: "#01B4BC",
        //   color: "#fff",
        // },
      },
    },

    blueBtn: {
      "& button": {
        backgroundColor: "#01B4BC",
        borderRadius: "10px",
        color: "#fff !important",
        textTransform: "capitalize",
        fontSize: "16px",
        fontWeight: 300,
        padding: "10px 40px",
        "&:hover": {
          backgroundColor: "#01B4BC",
          color: "#fff",
        },
        "& span": {
          width: "20px",
          height: "20px",
          marginLeft: "10px",
          borderColor: "#fff",
          borderBottomColor: "transparent",
        },
      },
    },

    without_bg_btn: {
      // borderWidth: "1px",
      // borderColor: "red",
      // border: "1px solid #01B4BC",
      // backgroundColor: "blue"
      "& button": {
        borderRadius: "10px",
        color: "#01B4BC !important",
        textTransform: "capitalize",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Roboto",
        padding: "8px 35px",
        border: "2px solid #01B4BC",
        "&:hover": {
          color: "#01B4BC",
        },
        "& span": {
          width: "20px",
          height: "20px",
          marginLeft: "10px",
          borderWidth: "1px",
          borderColor: "#01B4BC",
          borderBottomColor: "transparent",
        },
      },
    },

    without_bg_blue_btn: {
      "& button": {
        borderRadius: "10px",
        color: "#01B4BC !important",
        textTransform: "capitalize",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Roboto",
        padding: "8px 55px",
        border: "2px solid #01B4BC",
        "&:hover": {
          color: "#01B4BC",
        },
      },
    },

    continueShopping: {
      color: "#01B4BC",
      cursor: "pointer",
    },

    greyBtn: {
      "& button": {
        backgroundColor: "#C5C5C5",
        borderRadius: "10px",
        color: "#fff",
        textTransform: "capitalize",
        fontSize: "16px",
        fontWeight: 300,
        padding: "10px 50px",
        "&:hover": {
          backgroundColor: "#C5C5C5",
          color: "#fff",
        },
      },
    },

    whiteBtnSm: {
      "& button": {
        backgroundColor: "#fff",
        color: "#D95557",
        borderRadius: "5px",
        padding: "4px 25px",
        "&:hover": {
          backgroundColor: "#fff",
          color: "#D95557",
        },
      },
    },

    //common input background style --------------
    blueInput: {
      backgroundColor: "#01B4BC",
      borderRadius: "100px",
      "& input": {
        color: "#fff",
      },
      "& fieldset": {
        display: "none",
      },
    },

    btnWidth: {
      width: "50% !important",
    },

    //icon button style set -------------
    iconBtn: {
      "& button": {
        width: "40px",
        height: "40px",
        minWidth: "40px",
        padding: 0,
        backgroundColor: "#F2F3F5",
      },
      "& svg": {
        fontSize: "18px",
        color: "#01B4BC",
      },
    },

    socialBtnCustom: {
      position: "relative",
      "& svg": {
        color: "#fff !important",
        fontSize: "18px",
      },
    },

    googleLogin: {
      position: "absolute",
      top: 0,
      left: 0,
      opacity: 0,
    },

    facebookBtn: {
      backgroundColor: "#3B5998 !important",
      padding: "8px !important",
    },

    googleBtn: {
      backgroundColor: "#D95557",
    },

    appleBtn: {
      backgroundColor: "#000000",
    },

    //commonHeading-----------
    commonHeading: {
      "& h2": {
        color: "#3E4347",
        fontSize: "16px",
        fontWeight: 600,
      },
    },

    commonLink: {
      color: "#01B4BC",
      textDecoration: "none",
      fontWeight: 600,
      fontSize: "16px",
    },

    //window color set ---------
    windowColor: {
      backgroundColor: "#F2F3F5",
      height: "100%",
    },

    //card
    card: {
      boxShadow: "0px 1px 4px #0000001A",
      borderRadius: "13px",
      backgroundColor: "#fff",
      position: "relative",
    },
    cardHeader: {
      padding: "15px",
    },
    cardBody: {
      padding: "15px",
    },

    //wall post style set ------------
    wallPostFormInner: {
      backgroundColor: "#01B4BC",
      borderRadius: "10px",
      padding: "5px 10px 5px 30px",
      "& textarea": {
        color: "#FFFFFF",
        border: "0px",
        backgroundColor: "transparent",
        resize: "none",
        width: "100%",
        fontSize: "18px",
        outline: "none",
        "&::placeholder": {
          color: "#fff",
        },
      },
    },

    //common upload box ------------
    commonUploadBox: {
      border: "1px solid #0000003b",
      borderRadius: "10px",
      minHeight: "300px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexFlow: "column",
      "& svg": {
        color: "#999999",
        fontSize: "40px",
      },
      "& .container": {
        width: "100%",
        textAlign: "center",
        height: "200px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexFlow: "column",
        "& .dropzone": {
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexFlow: "column",
        },
      },
    },

    wallPostSubInner: {
      padding: "10px 20px",
      boxShadow: "0px 3px 0px #D3D8E066",
      borderRadius: "10px",
    },

    inlineButtons: {
      display: "flex",
      alignItems: "center",
      "& .css-1i8snpa-MuiButtonBase-root-MuiIconButton-root": {
        color: "#fff",
        width: "40px",
        height: "40px",
        display: "flex",
        alignItems: "center",
        padding: "0px 10px",
      },
    },

    wallUserInfo: {
      display: "flex",
      alignItems: "center",
      "& h2": {
        color: "#1D1F23",
        fontSize: "16px",
        fontWeight: 500,
        "& svg": {
          fontSize: "18px",
          marginLeft: "6px",
          color: "#01B4BC",
        },

        "& a": {
          textDecoration: "none",
          color: "#000",
        },
      },

      "& p": {
        color: "#01B4BC",
        fontSize: "12px",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        "& .css-i4bv87-MuiSvgIcon-root": {
          marginLeft: "5px",
          fontSize: "20px",
        },
      },
    },

    wallPostTopContent: {
      padding: "10px 0 0",
      width: "100%",
      "& p": {
        color: "#333333",
        fontSize: "16px",
        fontWeight: 400,
      },
    },

    //autocomplete list ui -------------
    autocompleteList: {
      position: "absolute",
      backgroundColor: "#fff",
      top: "50px",
      left: 0,
      width: "100%",
      maxHeight: "300px",
      listStyle: "none",
      margin: 0,
      padding: 0,
      borderRadius: "10px",
      boxShadow: "0px 1px 4px #0000001A",
      overflow: "hidden",
      "& li": {
        borderBottom: "1px solid #ccc",
        fontSize: "14px",
        "&:last-child": {
          border: "none",
        },
        "& a": {
          color: "#000",
          textDecoration: "none",
          display: "inline-block",
          width: "100%",
          padding: "10px",
        },
      },
    },

    //modal style --------------

    modalTextarea: {
      "& div": {
        "&::before": {
          border: "none",
        },
        "&:hover": {
          "&::before": {
            border: "none !important",
          },
        },
      },
    },

    tagMain: {
      width: "100%",
      margin: "25px 0",
    },

    searchTag: {
      backgroundColor: "#C5C5C5",
      position: "relative",
      color: "#fff",
      borderRadius: "100px",
      padding: "10px 20px 10px 50px",
      "& input": {
        color: "#fff",
      },
      "& div": {
        width: "100%",
        "&:hover": {
          "&::before": {
            border: "none !important",
          },
        },
        "&::before": {
          border: "none",
          display: "none",
        },
        "&::after": {
          display: "none",
        },
      },
      "& svg": {
        position: "absolute",
        top: "14px",
        left: "14px",
      },
    },

    //common table style ----------------
    commonTableHead: {
      "& th": {
        backgroundColor: "#01B4BC",
        color: "#fff",
      },
    },

    //common header search --------------
    headerSearch: {
      position: "relative",
      display: "inline-block",
      "& input": {
        backgroundColor: "#01B4BC",
        width: "100%",
        padding: "10px 15px 10px 40px",
        borderRadius: "10px",
        color: "#fff",
        fontsize: "16px",
      },
      "& fieldset": {
        border: "0px",
      },

      "& svg": {
        position: "absolute",
        top: "10px",
        left: "10px",
        color: "#fff",
      },
    },
    headindAndSearch: {
      display: "flex",
      // justifyContent: "space-around",
      alignItems: "center",
      marginBottom: "20px",
    },
    seasrchBar: {
      position: "absolute",
      right: 130,
    },

    filterSelect: {
      backgroundColor: "#D95557",
      borderRadius: "10px",
      "& label": {
        color: "#fff",
      },
      "& div": {
        marginTop: "0 !important",
        minHeight: "43px",
        color: "#fff",
        paddingLeft: "10px",
        "&::before": {
          display: "none",
        },
        "&::after": {
          display: "none",
        },
        "& svg": {
          color: "#fff",
        },
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },

    //common slider style --------------
    commonSlider: {
      width: "200px",
      marginRight: "20px",
      "& p": {
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "#D95557",
        "& span": {
          color: "#C5C5C5",
        },
      },
      "& span": {
        color: "#D95557",
      },
    },

    //common search list style ------------
    searchCommonList: {
      border: "1px solid #ccc",
      borderRadius: "4px",
      listStyle: "none",
      lineHeight: "30px",
      padding: 0,
      "& li": {
        cursor: "pointer",
        borderBottom: "1px solid #ccc",
        padding: "10px 15px",
        margin: "0 10px",
        "&:last-child": {
          border: "none",
        },
      },
    },

    commonModalStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 600,
      backgroundColor: "#fff",
      border: "2px solid #000",

      maxHeight: "400px",
      overflowY: "scroll",
    },

    imagesListModal: {
      width: "480px",
      height: "250px",
      borderBottom: "1px solid #ccc",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      overflow: "hidden",
      marginTop: 0,
      marginRight: "auto",
      marginBottom: 0,
      marginLeft: "auto",
      margin: "auto",
      padding: "20px 0",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "contain",
        objectPosition: "center",
      },
      "& video": {
        height: "100%",
        width: "100%",
      },
    },

    commonHeaderPosition: {
      position: "absolute",
      right: 0,
    },

    textFlexCenter: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    imageDragDrop: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      objectPosition: "center",
    },

    widthCardSet: {
      width: "50px",
      height: "30px",
    },

    dragList: {
      flexWrap: "nowrap",
      overflowX: "scroll",
      width: "50%",
      margin: "0 auto",
      height: "380px",
      "& li": {
        position: "relative",
        marginRight: "10px",
        border: "1px solid #ccc",
        borderRadius: "10px",
        "& svg": {
          position: "absolute",
          top: "4px",
          right: "4px",
          fontSize: "20px",
          cursor: "pointer",
          backgroundColor: "#fff",
          color: "#000",
          borderRadius: "100px",
        },
      },
    },

    oneImageDargList: {
      "& li": {
        position: "relative",
        marginRight: "10px",
        border: "1px solid #ccc",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        "& svg": {
          position: "absolute",
          top: "4px",
          right: "4px",
          fontSize: "20px",
          cursor: "pointer",
          backgroundColor: "#fff",
          color: "#000",
          borderRadius: "100px",
        },
      },
    },

    disabled: {
      PointerEvent: "none",
      opacity: 0.6,
    },

    commonLineClamp: {
      WebkitLineClamp: 3,
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordBreak: "break-word",
    },

    textUpper: {
      textTransform: "uppercase",
    },

    underlineText: {
      textDecoration: "underline",
    },

    dollarIconSet: {
      position: "relative",
      "& svg": {
        position: "absolute",
        top: "17px",
        left: "12px",
        zIndex: 1,
      },
      "& input": {
        paddingLeft: "50px",
      },
    },
    table_row_header: {
      color: "#fff",
    },
    checkout_btn: {
      fontFamily: "Roboto",
      fontWeight: "500",
      fontSize: "50px",
      alignItems: "center",
      color: "#000000",
    },
    shadow: {
      boxShadow: "1px 2px 9px grey",
      // padding: "10px",
      borderRadius: "8px",
      // border: '1px solid red',
      textAlign: "center",
      // width: "70%",
      // display: "flex",
      // justifyContent: "center",
      // marginLeft: "auto",
      // marginRight: "auto",
      // backgroundColor: "blue"
    },
    graphContainerPadding: {
      paddingTop: "50px",
    },
    f_family_Roboto: {
      fontFamily: "Roboto",
    },
    box_border: {
      // height: "56px",
      borderRadius: "4px",
      borderWidth: 0.5,
      borderStyle: "solid",
      borderColor: "#969696",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "15px",
    },
    datePicker: {
      width: "100%",
      maxHeight: "56px !important",
      height: "100% !important",
    },
    box_border_searchLocation: {
      width: "100%",
      borderRadius: "4px",
      borderWidth: 0.5,
      borderStyle: "solid",
      borderColor: "#969696",
    },
    tableColumntext: {
      color: "#fff !important",
      fontFamily: "Roboto !important",
      fontWeight: "500 !important",
      fontSize: "15px !important",
    },
    tableColumnBodytext: {
      color: "#000000 !important",
      fontFamily: "Roboto !important",
      fontWeight: "400 !important",
      fontSize: "15px !important",
    },
  };
});
