import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    initImmediate: false,
    lng: "en", //default language
    fallbackLng: "en", //when specified language translations not present
    //then fallbacklang translations loaded.
    debug: true,
    backend: {



      loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
      // loadPath: "./assets/i18n/translation/en.json",
    },

    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    // react: {
    //   wait: true,
    // },
  });

export default i18n;