import { Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import PrivateGuard from './PrivateGuard';
import Login from '../screens/userAuth/Login';
import Signup from '../screens/userAuth/SignUp';
import VerifyEmail from '../screens/userAuth/VerifyEmail';
import ForgotPassword from '../screens/userAuth/ForgotPassword';
import ResetPassword from '../screens/userAuth/ResetPassword';
import TermsCondition from '../screens/userAuth/TermsCondition';
import PrivacyPolicy from '../screens/userAuth/PrivacyPolicy';
import AcceptablePolicy from '../screens/userAuth/AcceptablePolicy';
// import PrivateRoute from './PrivateRoute'; // Adjust the import path if needed

const LazyPrivateRoutes = lazy(() => import('./PrivateRoute'));

const Routes: React.FC = () => {
  return (
    <Switch>
      <PrivateGuard path="/verify-email" component={VerifyEmail} />
      <PrivateGuard path="/reset-password" component={ResetPassword} />
      <PrivateGuard path="/forgot-password" component={ForgotPassword} />
      <PrivateGuard path="/signup" component={Signup} />
      <PrivateGuard path="/login" component={Login} />
      <PrivateGuard path="/" component={Login} exact />
      <Route path="/terms-condition" component={TermsCondition} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/acceptable-policy" component={AcceptablePolicy} />
      <Route path="/">
        <Suspense fallback={<div>Loading...</div>}>
          <LazyPrivateRoutes/>
        </Suspense>
      </Route>
    </Switch>
  );
};

export default Routes;