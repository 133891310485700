import React, { useState } from "react";
import { connect,useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Facebook } from "@mui/icons-material";

import { socialLogin, socialSignup } from "../../redux/action-creators/index";
import { facebooklink, RoutingLinks } from "../../utils/constants";
import { toast } from "../../utils/toastsMessage";

declare global {
  interface Window {
    FB: any;
    fbAsyncInit: any;
    js: object;
  }
}

const FacebookLogins = (props: any) => {
  const { t: translation } = useTranslation();
  React.useEffect(() => {
    setTimeout(() => {
      loadFbLoginApi();
    }, 1000);
  }, []);

  const [userInfo, setUserInfo] = useState({});
  const dispatch = useDispatch();

  // let FB = window.FB;

  React.useEffect(() => {
    if (props.isLoggedIn) {
      localStorage.setItem("userId", props.authData?.id);
      localStorage.setItem("userData", JSON.stringify(props.authData));
      localStorage.setItem("token", props.authData?.token);
      localStorage.removeItem("companyId");
      props.history.push(RoutingLinks.home);
    }
     
    if (props.authReducer?.isFound === false) {
      props.socialSignup({ ...userInfo });
    }
    //eslint-disable-next-line
  }, [props.isLoggedIn, props.authReducer?.isFound === false]);

  async function loadFbLoginApi() {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FB_ID,
        cookie: true, // enable cookies to allow the server to access
        // the session
        xfbml: true, // parse social plugins on this page
        version: "v2.1", // use version 2.1
      });
      window.FB.AppEvents.logPageView();
    };

    /**
     * Load the SDK asynchronously
     */
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = facebooklink;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }
  function testAPI() {
    window.FB.api(
      "/me",
      "get",
      {fields: 'id,first_name,last_name,gender,email'},

      async function (response: any) {
        if (response) {
          if (response.error) {
          } else if (response && response?.email) {
            let body = {
              id: response,
              first_name: response.first_name,
              last_name: response.last_name,
              email: response.email,
              type: "facebook",
            };
            setUserInfo(body);
            // await props.socialLogin(body);
            const data:any= await dispatch(socialLogin(body));
            if(data?.error)
            {
            
     
               return toast.error(translation(data?.error));
             }
          } else {
            alert(translation("alert_facebook_log"));
          }
        }
      }
    );
  }

  function statusChangeCallback(response: any) {
    if (response.status === "connected") {
      testAPI();
    } else if (response.status === "not_authorized") {
    } else {
      // testAPI();
    }
  }

  function handleFBLogin(this: any) {
    window.FB.login(
      () => {
        window.FB.getLoginStatus(function (response: any) {
          statusChangeCallback(response);
        });
      },
      {
        scope: "email",
        return_scopes: true,
      }
    );
  }

  return (
    <>
      <Facebook
        onClick={() => {
          handleFBLogin();
          return false;
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
    isLoggedIn: state.authReducer.isLoggedIn,
    errorMessage: state.commonReducer.errorMessage,
    authData: state.authReducer.authData,
    errorData: state.commonReducer.errorData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ socialLogin, socialSignup }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FacebookLogins)
);
