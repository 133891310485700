import { Action } from "../actions/AuthAction";
import { ActionType } from "../action-types/index";

export const initialState = {
  homePost: [],
  tricksPost: [],
  chatRoomPost: [],
  tailsPost: [],
  profilePost: [],
  postDetails: [],
  homeComments: [],
  tricksComments: [],
  chatRoomComments: [],
  tailsComments: [],
  profileComments: [],
  postDetailsComments: []
};

/**
 * user information stored in global state of redux
 * @param state
 * @param action
 * @returns
 */
export const postReducer = (state: object = initialState, action: any) => {
  switch (action.type) {
    case ActionType.HOMEPOST:
      return {
        ...state,
        homePost: action.payload,
      };
    case ActionType.TRICKSPOST:
      return {
        ...state,
        tricksPost: action.payload,
      };
    case ActionType.CHATROOMPOST:
      return {
        ...state,
        chatRoomPost: action.payload
      };
    case ActionType.TAILSPOST:
      return {
        ...state,
        tailsPost: action.payload
      };
    case ActionType.PROFILEPOST:
      return {
        ...state,
        profilePost: action.payload
      };
    case ActionType.POSTDETAIL:
      return {
        ...state,
        postDetails: action.payload
      };
    // all types comments action
    case ActionType.HOMECOMMENTS:
      return {
        ...state,
        homeComments: action.payload
      };
    case ActionType.TRICKSCOMMENTS:
      return {
        ...state,
        tricksComments: action.payload
      };
    case ActionType.CHATROOMCOMMENTS:
      return {
        ...state,
        chatRoomComments: action.payload
      };
    case ActionType.TAILSCOMMENTS:
      return {
        ...state,
        tailsComments: action.payload
      };
    case ActionType.PROFILECOMMENTS:
      return {
        ...state,
        profileComments: action.payload
      };
      case ActionType.POSTDETAILCOMMENTS:
        return {
          ...state,
          postDetailsComments: action.payload
        }
    default:
      return state;
  };
};
