import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from './reducers';
/**
 * Redux store setup with reducers
 */ 
export const store = createStore(
    rootReducer,
    {},
    applyMiddleware(thunk)
)