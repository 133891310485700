import { Action } from "../actions/AuthAction";
import { ActionType } from "../action-types/index";

export const initialState = {
  productCategoryList: [],
};

/**
 * user information stored in global state of redux
 * @param state
 * @param action
 * @returns
 */
export const productReducer = (
  state: object = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.PRODUCTCATEGORYLIST:
      return {
        ...state,
        productCategoryList: action.payload,
      };
    default:
      return state;
  }
};
