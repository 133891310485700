import cogoToast from "cogo-toast";

class Toaster {
  success = (message: string) => {
    const options: object = {
      position: "top-right",
      heading: "Success",
      
    };
    cogoToast.success(message, options);
  };

  error = async (message: string) => {
    const options: object = {
      position: "top-right",
      heading: "Error",
      hideAfter:2
    };
    await cogoToast.error(message, options);
    
  };

  info = (message: string) => {
    const options: object = {
      position: "top-right",
      heading: "Info",
    };
    cogoToast.info(message, options);
  };
}

export const toast = new Toaster();
