import { ActionType } from "../action-types/index";

export const initialState = {
  petday: [],
  friendList: [],
  peopleYouMayKnow: [],
  // tailsPost: [],
  // homeComments: [],
  // profilePost: [],
  // tricksComments: [],
  // chatRoomComments: [],
  // tailsComments: [],
  // profileComments: []
};

/**
 * user information stored in global state of redux
 * @param state
 * @param action
 * @returns
 */
export const rightSideBarReducer = (state: object = initialState, action: any) => {
  switch (action.type) {
    case ActionType.PETOFTHEDAY:
      return {
        ...state,
        petday: action.payload,
      };
    case ActionType.FRIENDREQUEST:
      return {
        ...state,
        friendList: action.payload
      };
    case ActionType.PEOPLEYOUMAYKNOW:
      return {
        ...state,
        peopleYouMayKnow: action.payload
      }
    default:
      return state;
  };
};
