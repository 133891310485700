export enum ActionType {
  FAILURE = "failure",
  LOGIN = "login",
  SIGNUP = "signup",
  UPDATEPROFILE = "update_profile",
  SOCIALLOGIN = "socialLogin",
  ERROR = "error",
  LOGOUTUSER = "logout_user",
  PRODUCTCATEGORYLIST = "product_category_list",
  Notification = "Notification",
  NotificationCount = "notification_count",
  ADDTOCART = "add_to_cart",
  HOMEPOST = "home_post",
  TRICKSPOST = "tricks_post",
  CHATROOMPOST = "chatroom_post",
  TAILSPOST = "tails_post",
  PROFILEPOST = "profile_post",
  POSTDETAIL = "post_detail",
  HOMECOMMENTS = "home_comments",
  TRICKSCOMMENTS = "tricks_comments",
  CHATROOMCOMMENTS = "chatroom_comments",
  TAILSCOMMENTS = "tails_comments",
  PROFILECOMMENTS = "profile_comments",
  POSTDETAILCOMMENTS = "post_detail_comments",
  PETOFTHEDAY = "pet_of_the_day",
  FRIENDREQUEST = "friend_request",
  PEOPLEYOUMAYKNOW = "people_you_may_know",
}