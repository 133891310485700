import { BrowserRouter, Switch } from "react-router-dom";
import { Route } from "react-router";
import Routes from "./routes/PublicRoutes";
import '../src/components/i18n/I18n'
import SelectLanguage from "./utils/lang";

function App() {
  
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Routes} />
      </Switch>
      <SelectLanguage />
    </BrowserRouter>
  );
}

export default App;
