import moment from "moment";

const getHoursMinutes = (startTime: moment.Moment, endTime: moment.Moment) => {
  // calculate total duration
  var duration: any = moment.duration(endTime.diff(startTime));

  // duration in hours
  var hours = parseInt(duration.asHours());

  // duration in minutes
  var minutes = parseInt(duration.asMinutes()) % 60;

  return { hours, minutes };
};
export const getTimeDiff = (date: any, oldDate = true) => {
  if (oldDate) {
    return moment().diff(moment(date), "minutes") < 60
      ? `${moment().diff(moment(date), "minutes")} ${moment().diff(moment(date), "minutes") > 1 ? "MINUTES" : "MINUTE"
      }`
      : moment().diff(moment(date), "hours") < 25
        ? `${moment().diff(moment(date), "hours")} ${moment().diff(moment(date), "hours") > 1 ? "HOURS" : "HOUR"
        }`
        : `${moment().diff(moment(date), "days")} ${moment().diff(moment(date), "days") > 1 ? "DAYS" : "DAY"
        }`;
  } else {
    return moment(date).diff(moment(), "minutes") < 60
      ? `${moment(date).diff(moment(), "minutes")} ${moment(date).diff(moment(), "minutes") > 1 ? "MINUTES" : "MINUTE"
      }`
      : moment().diff(moment(date), "hours") < 25
        ? `${getHoursMinutes(moment(), moment(date)).hours} : ${getHoursMinutes(moment(), moment(date)).minutes
        } ${moment(date).diff(moment(), "hours") > 1 ? "HOURS" : "HOUR"}`
        : `${moment(date).diff(moment(), "days")} ${moment(date).diff(moment(), "days") > 1 ? "DAYS" : "DAY"
        }`;
  }
};

/**
 * Receiving value argument for checking whether it has any value or not
 * @param value // any value
 * @returns // boolean
 */
export const isEmpty = (value: any) => {
  let isEmpty = false;
  if (!value) {
    isEmpty = true;
  } else if (typeof value == "undefined") {
    isEmpty = true;
  } else if (value.toString().trim().length === 0) {
    isEmpty = true;
  } else if (typeof value === "object" && Object.keys(value).length === 0) {
    isEmpty = true;
  }
  return isEmpty;
};

/**
 * Finds the code of the emoji by title
 * @param title // emoji title
 * @returns // code for the emoji
 */
export const getEmoji = (title: string) => {
  let result: any;
  switch (title) {
    case "like":
      result = <span className="reaction-icon">&#128077;</span>;
      break;
    case "heart":
      result = <span className="reaction-icon">&#128151;</span>;
      break;
    case "laugh":
      result = <span className="reaction-icon">&#128515;</span>;
      break;
    case "angry":
      result = <span className="reaction-icon">&#128545;</span>;
      break;
    default:
      result = <span className="reaction-icon">&#128077;</span>;
      break;
  }
  return result;
};

/**
 * Method to capitalize a string's first character
 * @param string any string
 * @returns The capitalized string
 */
export const capitalize = (string: string) => {
  return string?.toLowerCase()?.replace(/\b[a-z]/g, function (letter) {
    return letter?.toUpperCase();
  });
};

/**
 * To convert a javascript file object to base64 string
 * @param file javascript file object
 * @returns promise
 */

export const FileToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const isJsonString = (userData) => {
  try {
    JSON.parse(userData);
  } catch (e) {
    return false;
  }
  return true;
};

export const otherText = (text: string) => <div style={{
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}}
><p>{text ? text : ""}</p></div>
