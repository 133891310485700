
import { useTranslation } from "react-i18next";
import {useEffect,useCallback} from 'react';

const SelectLanguage = () => {
    const userData = localStorage.getItem("userData");
    const userDataObject = JSON.parse(userData);
    const { i18n } = useTranslation();
  
    const selectLanguage = useCallback(()=>{
      const lang = {
        "Hindi" :"hi",
        "English" :"en",
        "French" :"fr",
        "German" :"de",
        "Spanish" : "sp"
    }
      i18n.changeLanguage(lang[userDataObject?.language] ? lang[userDataObject?.language] : "en");
    },[i18n,userDataObject?.language]);
    
      useEffect(() => {
        selectLanguage()
      }, [selectLanguage]);

  return (<div>
     {selectLanguage}
  </div>
  );
};

export default SelectLanguage;