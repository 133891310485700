import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { UserCommonStyles } from "../../styles/CommonStyles";
import { HeaderStyles } from "../../styles/Header";

import Logo from "../../images/logo-petlover.png";

const AcceptablePolicy: React.FC = () => {
  const commonStyle = UserCommonStyles();
  const styleClasses = HeaderStyles();
  return (
    <div className={commonStyle.p20}>
      <Box className={`${commonStyle.mb20} ${styleClasses.logo}`}>
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
      </Box>
      <Typography variant="h5">PETLOVERSCHAT Acceptable Use Policy</Typography>
      <p>
        This PETLOVERSCHAT Acceptable Use Policy ("AUP") applies to the use of
        any platform or website provided by us (PETLOVERSCHAT), whether we
        provide it directly or use another party to provide it to you (each, a
        "PETLOVERSCHAT Platform"). This AUP is designed to ensure compliance
        with the laws and regulations that apply to PETLOVERSCHAT platforms.
        These terms are so important that we cannot allow advertising or create
        an account on PETLOVERSCHAT unless you agree to them. By using the
        PETLOVERSCHAT Platform, you are agreeing to these terms.
      </p>
      <p>
        If you are using any PETLOVERSCHAT Platform, this AUP applies to you.
        Every user of ours agrees to abide by this AUP and is responsible for
        any violations. You are not allowed to assist or engage others in a way
        that would violate this AUP. We will enforce and ensure compliance with
        this AUP by using methods we consider to be appropriate, such as
        complaint and email failure monitoring. We may also suspend or terminate
        your use of PETLOVERSCHAT Platforms pursuant to our{" "}
        <Link to="/terms-condition"> Terms of Use policy </Link>
        for violations of this AUP.
      </p>
      <p>
        We periodically update these terms and we will let you know by posting a
        revised copy on our website. You agree to review the AUP on a regular
        basis and always remain in compliance.
      </p>

      <Typography variant="h6">1. Reporting Suspected Violations</Typography>

      <p>
        We encourage recipients of email messages sent using the PETLOVERSCHAT
        Platform to report suspected violations of this AUP to us by forwarding
        a copy of the received email with FULL headers to info@petloverschat.com.
        We have a policy to investigate all of these reports and to respond in
        the way we consider appropriate.
      </p>
      <p>
        If you know of or suspect a violation of this AUP, you will promptly
        notify us in writing of the known or suspected violation of this AUP.
      </p>

      <Typography variant="h6">
        2. Proper Usage of the PETLOVERS Platform
      </Typography>
      <p>
        You will respect the limits that apply to your use the PETLOVERSCHAT
        <Link to="/terms-condition"> Terms of Use</Link>. We may periodically
        update or change these Terms of Use. We will let you know by posting a
        revised copy on our website. You agree to review the Terms of Use on a
        regular basis and always remain in compliance
      </p>

      <p>
        In addition, and without limiting the other requirements in this AUP,
        you may not (directly or indirectly) use the PETLOVERSCHAT Platform with
        content, or in a manner that:
      </p>

      <ul>
        <li>
          is threatening, abusive, harassing, stalking, hateful, or defamatory;
        </li>
        <li>is deceptive, false, misleading or fraudulent;</li>
        <li>
          is invasive of another's privacy or otherwise violates another’s legal
          rights (such as rights of privacy and publicity);
        </li>
        <li>contains vulgar, obscene, indecent or unlawful material;</li>
        <li>infringes a third party's intellectual property right(s);</li>
        <li>
          publishes, posts, uploads, or otherwise distributes any software,
          music, videos, or other material protected by intellectual property
          laws (or by rights of privacy or publicity) unless you have all
          necessary rights and consents to do so;{" "}
        </li>
        <li>
          uploads files that contain viruses, corrupted files, or any other
          similar software or programs that may damage the operation of another
          person's computer;
        </li>
        <li>
          downloads any file that you know, or reasonably should know, cannot be
          legally distributed in that way;
        </li>
        <li>
          falsifies or deletes any author attributions, legal or proprietary
          designations, labels of the origin or source of software, or other
          material contained in a file that is uploaded;
        </li>
        <li>
          restricts or inhibits any other user of the PETLOVERSCHAT Platform
          from using and enjoying our website and/or the PETLOVERSCHAT Platform;
        </li>
        <li>
          harvests or otherwise collects information about others, including
          e-mail addresses, without their consent;
        </li>
        <li>
          violates the usage standards or rules of an entity affected by your
          use, including without limitation any ISP, ESP, or news or user group
          (and including by way of example and not limitation circumventing or
          exceeding equipment use rights and restrictions and/or location and
          path identification detail);
        </li>
        <li>is legally actionable between private parties;</li>
        <li>
          contains content which exploits crises or controversial political or
          social issues for commercial purposes.
        </li>
        <li>
          is not a good faith use of the platform, such as uploading Contacts in
          excess of your Contact tier, emailing those Contacts and then purging
          them shortly thereafter; and/or
        </li>
        <li>
          is in violation of any applicable local, state, national or
          international law or regulation, including all export laws and
          regulations and without limitation the Controlling the Assault of
          Non-Solicited Pornography and Marketing Act (CAN-SPAM Act) (15 U.S.C.
          § 7701 et seq.), the U.S Telephone Consumer Protection Act of 1991 (47
          U.S.C. § 227), the Do-Not-Call Implementation Act of 2003 (15 U.S.C. §
          6152 et seq.; originally codified at § 6101 note), the General Data
          Protection Regulation (2016/679), the Directive 2000/31/EC of the
          European Parliament and Council of 8 June 2000, on certain legal
          aspects of information society platforms, in particular, electronic
          commerce in the Internal Market ('Directive on Electronic Commerce'),
          along with the Directive 2002/58/EC of the European Parliament and
          Council of 12 July 2002, concerning the processing of personal data
          and the protection of privacy in the electronic communications sector
          ('Directive on Privacy and Electronic Communications'), regulations
          promulgated by the U.S. Securities Exchange Commission, any rules of
          national or other securities exchange, including without limitation,
          the New York Stock Exchange, the American Stock Exchange or the
          NASDAQ, the Personal Information Protection and Electronic Documents
          Act (PIPEDA) (S.C. 2000, c. 5), Canada’s Anti-Spam Legislation (CASL)
          (S.C. 2010, c. 23), Japan’s Act on Regulation of Transmission of
          Specified Electronic Mail (Act No. 26 of April 17, 2002) and any
          regulations having the force of law or laws in force in your or your
          email recipient's country of residence;
        </li>
        <li>
          encourages, promotes, facilitates or instructs others to engage in
          illegal activity;
        </li>
        <li>
          promotes, encourages, or facilitates: hate speech, violence,
          discrimination based on race, color, sexual orientation, marital
          status, gender or identity expression, parental status, religion or
          creed, national origin or ancestry, sex, age, physical or mental
          disability, veteran status, genetic information, citizenship and/or
          any other characteristic protected by law.
        </li>
        <li>
          Organizations or individuals who promote, encourage, or facilitate
          hate speech, violence, discrimination, either through their own
          content or through distribution of user generated content, are
          prohibited from using the PETLOVERSCHAT Platform, regardless of
          whether the PETLOVERSCHAT Platform is used specifically for the
          prohibited activities. Violation of these standards may result in
          termination of your use of the PETLOVERSCHAT Platform.
        </li>
      </ul>

      <p>
        You are responsible for moderating user generated content or user
        activity on your platform or platform. User generated content that
        violates these standards may result in termination of your use of the
        PETLOVERSCHAT Platform.
      </p>
      <p>
        You will use the PETLOVERSCHAT Platform in accordance with its{" "}
        <Link to="/terms-condition"> Terms of Use</Link> policy and will not:
        (i) willfully tamper with the security of the PETLOVERSCHAT Platform or
        tamper with our customer accounts; (ii) access data on the PETLOVERSCHAT
        Platform not intended for you; (iii) log into an account on the
        PETLOVERSCHAT Platform that you are not authorized to access; (iv)
        attempt to probe, scan or test the vulnerability of any PETLOVERSCHAT
        Platform or to breach the security or authentication measures without
        proper authorization; (v) willfully render any part of the PETLOVERSCHAT
        Platform unusable; (vi) lease, distribute, license, sell or otherwise
        commercially exploit the PETLOVERSCHAT Platform; (vii) provide to third
        parties any evaluation version of the PETLOVERSCHAT Platform without our
        prior written consent.
      </p>

      <Typography variant="h6">3. PETLOVERS Trademark Use</Typography>
      <p>
        Unless you have our express prior written permission, you may not use
        any name, logo, tagline or other mark of ours or the PETLOVERSCHAT
        Platform, or any identifier or tag generated by the PETLOVERSCHAT
        Platform, including without limitation: (a) as a hypertext link to any
        website or other location (except as provided for or enabled expressly
        by us); or (b) to imply identification with us as an employee,
        contractor, agent or other similar representative capacity. You also
        agree not to remove or alter any of these items as we may have provided
        or enabled.
      </p>

      <Typography variant="h6">4. General Terms</Typography>
      <p>
        We may immediately suspend your access to the PETLOVERSCHAT Platform if
        you breach this AUP or don’t respond to us in a reasonable period after
        we’ve contacted you about a potential breach of this AUP. We may also
        suspend your access as we explain in our Terms of Use, and, if you
        breach this AUP, we may terminate your account for cause. You
        acknowledge we may disclose information regarding your use of any
        PETLOVERSCHAT Platform to satisfy any law, regulation, government
        request, court order, subpoena or other legal process. If we make this
        type of required disclosure we will notify you, unless we are required
        to keep the disclosure confidential.
      </p>
      <p>
        We are not obligated to, but may choose to, remove any prohibited
        materials and deny access to any person who violates this AUP. We
        further reserve all other rights.
      </p>
      <p>
        We may update and change any part or all of this AUP. If we update or
        change this AUP, the updated AUP will be posting the revised copy on our
        website. When we change this AUP, the "Last Modified" date above will be
        updated to reflect the date of the most recent version. We encourage you
        to review this AUP periodically.
      </p>
      <br />
      <p>Last Modified: February 14, 2022</p>
    </div>
  );
};

export default AcceptablePolicy;
