import { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { Box } from "@mui/system";
import { IconButton } from "@mui/material";
import { Google } from "@mui/icons-material";

import { socialLogin, socialSignup } from "../../redux/action-creators/index";

import { RoutingLinks } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { toast } from "../../utils/toastsMessage";

const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const GoogleLogin = (props: any) => {
  let auth2;
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();
  const [disable,setdisable]=useState(false)


  /**
   * Load function
   */
  const loadFunction =async ()=>{
    setdisable(true)
    localStorage.setItem("click","0");
    
    
    const errorCallback = oneError.bind(this);
    window.gapi.load("auth2", () => {
      auth2 = window.gapi.auth2.init({
        client_id: googleClientID,
      })
      auth2
        .then(() => {})
        .catch((error: string) => 
        console.log("error is", error),
        setdisable(false),
        
        );
    });
    window.gapi.load("signin2", function () {
      var opts = {
        client_id: googleClientID,
        onsuccess: onSuccess,
        onfailure: errorCallback,
      };
      window.gapi.signin2.render("loginButton", opts);
    });
  }
   

  // useEffect(() => {
  //   setTimeout(() => {
  //     loadFunction();
  //   }, 1000);
  // }, [loadFunction]);

  const [idToken, setIdToken] = useState("");

  useEffect(() => {
    if (props.isLoggedIn) {
      const user = props.authReducer.authData;
      localStorage.setItem("userId", user.id);
      localStorage.setItem("userData", JSON.stringify(user));
      localStorage.setItem("token", user.token);
      props.history.push(RoutingLinks.home);
    }

    if (
      props.authReducer.isFound === false &&
      props.authReducer.loginType === "google"
    ) {
      props.socialSignup({ idToken, type: "google" });
    }
    //eslint-disable-next-line
  }, [props.isLoggedIn, props.authReducer.isFound === false]);

  let key: any = "";
  let value: any = "";
  const getGoogleIdToken = async (event: any) => {
    for ([key, value] of Object.entries(event)) {
      if (Object.keys(value).includes("id_token") === true) {
        return value.id_token;
      }
    }
  };

  const onSuccess = async (event: any) => {
    let id_token = await getGoogleIdToken(event);
    const click=localStorage.getItem("click")
   

    if (id_token !== undefined) {
      setIdToken(id_token);
      // await props.socialLogin({ idToken: id_token, type: "google" });
      if(click!=="1")
      {
        const data:any= await dispatch(socialLogin({ idToken: id_token, type: "google" }));
      if(data?.error)
      {
           localStorage.setItem("click","1")
           setdisable(false)
         return toast.error(translation(data?.error));
       }
     
      }
     
    }
  };


  const oneError = () => {};
  return (
    <Box>
      <IconButton disabled={disable} type="button" id="loginButton" onClick={loadFunction}>
        <Google />
      </IconButton>
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    isLoggedIn: state.authReducer.isLoggedIn,
    errorMessage: state.commonReducer.errorMessage,
    authData: state.authReducer.authData,
    errorData: state.commonReducer.errorData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ socialLogin, socialSignup }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GoogleLogin)
);
