import { makeStyles } from "@mui/styles";

export const UserAuthStyles = makeStyles((theme) => {
  return {
    loginSide: {
      backgroundColor: "#01B4BC",
      height: "100%",
    },

    resendTextOtp: {
      textAlign: "center",
      "& button": {
        textTransform: "capitalize",
        color: "#D95557",
      },
    },

    logoSize: {
      width: "60%",
      marginTop: "90px",
    },

    confirmAmountModal: {
      textAlign: "center",
      backgroundColor: "#fff",
      width: "400px",
      height: "300px",
      margin: "0 auto",

      borderRadius: "10px",

      border: "1px solid",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexFlow: "column",
      "& svg": {
        color: "#D95557",
        fontSize: "70px",
      },

      "& p": {
        margin: "15px 0",
      },
    },

    authForm: {
      backgroundColor: "#F2F3F5",
      height: "100vh",
      padding: "20px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    formGroup: {
      width: "100%",
      padding: "0 80px",
      "& h5": {
        fontWeight: "500",
        marginBottom: "30px",
      },
    },

    formSub: {
      "& h5": {
        fontWeight: "500",
        marginBottom: "30px",
      },
    },

    orText: {
      textAlign: "center",
      color: "#999999",
      position: "relative",
      margin: "25px 0",
      "&::after": {
        position: "absolute",
        content: '" "',
        backgroundColor: "#999999",
        width: "100%",
        height: "1px",
        top: "12px",
        left: 0,
      },
      "& p": {
        display: "inline-block",
        fontSize: "14px",
        backgroundColor: "#F2F3F5",
        zIndex: "1",
        position: "relative",
        padding: "0 15px",
      },
    },

    forgotLink: {
      textDecoration: "none",
      fontWeight: 500,
      fontSize: "14px",
    },

    socialLogin: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& li": {
        padding: "0 8px",
      },
    },

    authDescFooter: {
      marginTop: "15px",
      "& p": {
        color: "#999999",
        fontSize: "14px",
      },
      "& a": {
        color: "#D95557",
        fontWeight: 600,
        textDecoration: "none",
      },
    },

    customSearchList: {
      position: "absolute",
      zIndex: 2,
      "& ul": {
        backgroundColor: "#fff",
      },
    },

    cardSaveList: {
      listStyle: "none",
      padding: 0,
      margin: 0,
      "& li": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      "& .PaymentMethod__card": {
        borderBottom: "1px solid",
        marginBottom: "20px",
      },
    },

    cardCustomWidth: {
      width: "80%",
      margin: "0 auto",
      marginTop: "70px",
      "& .StripeElement": {
        border: "1px solid #c4c4c4",
        borderRadius: "4px",
        padding: "18px 10px",
      },
    },
  };
});
