import { Action } from "../actions/AuthAction";
import { ActionType } from "../action-types/index";

export const initialState = {
  loading: false,
  errorData: {},
  errorMessage: "",
  isRegistered: false,
};

/**
 * user information stored in global state of redux
 * @param state
 * @param action
 * @returns
 */
export const commonReducer = (state: object = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.ERROR:
      return {
        ...state,
        errorData: action.payload?.error,
        errorMessage: action.payload?.errorMessage,
      };
    default:
      return state;
  }
};
