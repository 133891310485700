import axios from "../../services/axiosService";
import { Dispatch } from "redux";
import { ActionType } from "../action-types/index";
import { Action } from "../actions/AuthAction";
const URL = process.env.REACT_APP_API_URL;

/**
 * verify otp
 * @param data
 * @returns
 */
export const verifyOtp = (data: any) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = await axios.post(`${URL}/api/v1/users/verifyOtp`, data);
    return response;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * update otp
 * @param data
 * @returns
 */
export const updateOtp = (data: any) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = await axios.post(`${URL}/api/v1/users/newOtp`, data);
    return response;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};
