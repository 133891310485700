import { Typography } from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { UserCommonStyles } from "../../styles/CommonStyles";
import { HeaderStyles } from "../../styles/Header";

import Logo from "../../images/logo-petlover.png";

const TermsCondition: React.FC = () => {
  const commonStyle = UserCommonStyles();
  const styleClasses = HeaderStyles();

  return (
    <div className={commonStyle.p20}>
      <Box className={`${commonStyle.mb20} ${styleClasses.logo}`}>
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
      </Box>
      <Typography variant="h5">Terms of Use</Typography>
      <p>
        By clicking "sign in" or "create profile" and entering or otherwise
        using our website or mobile application (the "Site"), you agree to and
        accept these terms and conditions.
      </p>
      <p>
        THESE TERMS AND CONDITIONS, AS WELL AS ANY OTHER PRODUCT AND SERVICE
        SPECIFIC TERMS ON OUR SITE, CONSTITUTE YOUR SERVICE AGREEMENT
        ("AGREEMENT") WITH PETLOVERSCHAT, LLC (“PETLOVERSCHAT”). THIS AGREEMENT
        SETS FORTH THE LEGALLY BINDING TERMS FOR YOUR USE OF OUR SITE AND YOUR
        PURCHASE OR USE OF ANY PRODUCTS OR SERVICES OFFERED ON THE SITE. BY
        ACCESSING AND/OR USING THE SITE, YOU REPRESENT AND WARRANT THAT YOU HAVE
        THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THE AGREEMENT AND TO
        ABIDE BY ALL OF THE TERMS AND CONDITIONS SET FORTH HEREIN. YOU MAY NOT
        ACCESS OR USE THIS SITE OR ACCEPT THIS AGREEMENT UNLESS YOU ARE AT LEAST
        18 YEARS OLD.
      </p>
      <p>
        ARBITRATION NOTICE: THESE TERMS CONTAIN AN ARBITRATION CLAUSE. YOU AND
        PETLOVERSCHAT AGREE THAT DISPUTES BETWEEN US WILL BE RESOLVED BY
        MANDATORY BINDING ARBITRATION, AND YOU AND PETLOVERS WAIVE ANY RIGHT TO
        PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
      </p>

      <Typography variant="h5">Privacy Policy</Typography>
      <p>
        Please review our{" "}
        <a href="/privacy-policy" target="_blank">
          Privacy Policy
        </a>
        , which also governs your use of our Site, to understand our practices.
      </p>

      <Typography variant="h5">Electronic Communications</Typography>
      <p>
        When you use our Site, or send e-mails, and other electronic
        communications from your desktop or mobile device to us, you are
        communicating with us electronically. By sending, you consent to receive
        reply communications from us electronically in the same format and you
        can retain copies of these communications for your records. You agree
        that all agreements, notices, disclosures, and other communications that
        we provide to you electronically satisfy any legal requirement that such
        communications be in writing.
      </p>

      <Typography variant="h5">Profiles</Typography>
      <p>
        In order to use certain features of the Site, you must create a profile
        with PETLOVERSCHAT ("PETLOVERSCHAT Profile") and provide certain
        information about yourself as prompted by the Site registration form.
        You represent and warrant that: (a) all required registration
        information you submit is truthful and accurate; (b) you will maintain
        the accuracy of such information; and (c) your use of the Site does not
        violate any applicable law or regulation or the terms of this Agreement.
      </p>
      <p>
        You are responsible for maintaining the confidentiality of your
        PETLOVERSCHAT Profile login information. You are fully responsible for
        all activities that are associated with your PETLOVERSCHAT Profile
        (including but not limited to any posted content, use of the Site, or
        correspondence from your profile to PETLOVERSCHAT). You agree to
        immediately notify PETLOVERSCHAT of any unauthorized use or suspected
        unauthorized use of your PETLOVERSCHAT Profile or any other breach of
        security. When you provide PETLOVERSCHAT with such notice, PETLOVERSCHAT
        will suspend or otherwise secure your Profile to prevent future
        unauthorized activity.
      </p>

      <Typography variant="h5">Permissible Uses of Site</Typography>
      <p>
        Subject to the terms of this Agreement, PETLOVERSCHAT grants you a
        limited, non-transferable, non-exclusive, license to access and make
        personal use of the Site. This license does not include any resale or
        commercial use of the Site features or content, or the right to access
        or use the Site for any of the restricted purposes set forth below.
        PETLOVERSCHAT may terminate this license at any time for any reason.
      </p>
      <p>
        The rights granted to you in this Agreement are subject to the following
        restrictions: (a) you shall not use the Site to place an order on behalf
        of yourself or a third party for resale to that third party; (b) you
        shall not license, sublicense, reproduce, sell, rent, lease, transfer,
        assign, distribute, host, or otherwise commercially exploit the Site,
        its products or its content without the express written consent of
        PETLOVERSCHAT; (c) you shall not modify, make derivative works of,
        disassemble, reverse compile or reverse engineer any part of the Site;
        (d) you shall not access the Site in order to build a similar or
        competitive service, or to download, copy or collect content or profile
        information for the benefit of another merchant; (e) except as expressly
        stated herein, no part of the Site may be copied, reproduced,
        distributed, republished, downloaded, displayed, posted or transmitted
        in any form or by any means without PETLOVERSCHAT's express written
        consent; and (f) you shall not frame or utilize framing techniques to
        enclose any trademark, logo, or other proprietary information or content
        (including images and text descriptions) of the Site without
        PETLOVERSCHAT's express written consent.
      </p>
      <p>
        PETLOVERSCHAT reserves the right, at any time, to modify, suspend, or
        discontinue the Site or any part thereof with or without notice. You
        agree that PETLOVERSCHAT will not be liable to you or to any third party
        for any modification, suspension, or discontinuance of the Site or any
        part thereof.
      </p>

      <Typography variant="h5">Intellectual Property Rights</Typography>
      <p>
        The Site contains valuable trademarks and service marks owned and used
        by PETLOVERSCHAT, including but not limited to, PETLOVERSCHAT app, the
        PETLOVERSCHAT design logo, the PETLOVERSCHAT Design marks,
        PETLOVERSCHAT.COM, among numerous other trademarks used on this Site,
        are registered to and/or otherwise protected by PETLOVERSCHAT under
        United States, Canada and/or international intellectual property laws
        (collectively, the "PETLOVERSCHAT Marks"). Any use of the PETLOVERSCHAT
        Marks without the prior written permission of PETLOVERSCHAT is strictly
        prohibited. The arrangement and layout of the Site, including but not
        limited to, the PETLOVERSCHAT Marks, images, text, graphics, buttons,
        screenshots, and other content or material (collectively, the "Site
        Content"), are the sole and exclusive property of PETLOVERSCHAT.
      </p>
      <p>
        UNAUTHORIZED COPYING, REPRODUCTION, MODIFYING, REPUBLISHING, UPLOADING,
        DOWNLOADING, POSTING, TRANSMITTING, MAKING DERIVATIVE WORKS OF OR
        DUPLICATING ALL OR ANY PART OF THE SITE IS PROHIBITED.
      </p>

      <Typography variant="h5">
        Limitations Regarding Information, Articles and Advice
      </Typography>
      <p>
        PETLOVERSCHAT offers information, articles, and advice to pet owners
        through this site for educational purposes only. Any information offered
        through this site is not intended to diagnose, treat or cure your pet
        and is not a substitute for veterinary care provided by a licensed
        veterinarian. For any medical or health related advice concerning the
        care and treatment of your pet contact your veterinarian or local animal
        hospital.
      </p>

      <Typography variant="h5">User Content</Typography>
      <p>
        "User Content" of the Site user means any and all information and
        content that such user submits to PETLOVERSCHAT by any means, including
        through social media (e.g., Facebook, YouTube, Instagram, Pinterest,
        Twitter), or uses with the Site (e.g., a user profile, User to User
        communication, selling products, pet adoption). You are solely
        responsible for your User Content. You assume all risks associated with
        use of your User Content, including any reliance on its accuracy,
        completeness or usefulness by others, or any disclosure of your User
        Content that makes you or any third party personally identifiable. You
        hereby affirm, represent, and warrant that (i) you own, or have the
        necessary licenses, rights, and/or consents to use your User Content
        with the Site as described herein and (ii) your User Content does not
        violate the Acceptable Use Policy. You may not state or imply that your
        User Content is in any way provided, sponsored or endorsed by
        PETLOVERSCHAT. Because you alone are responsible for your User Content
        (and not PETLOVERSCHAT), you may expose yourself to liability if, for
        example, your User Content violates the Acceptable Use Policy.
        PETLOVERSCHAT is not obligated to backup any User Content, makes no
        representation that it will do so, and you agree that PETLOVERSCHAT may
        delete User Content at any time.
      </p>
      <p>
        By submitting your User Content or using it with the Site , you
        automatically grant, and you represent and warrant that you have the
        right to grant, to PETLOVERSCHAT an irrevocable, nonexclusive,
        royalty-free and fully paid, worldwide license to reproduce, distribute,
        publicly display and perform, prepare derivative works of, incorporate
        into other works, and otherwise use your User Content, and to grant
        sublicenses of the foregoing, solely for the purposes of including your
        User Content in the Site, PETLOVERSCHAT social media platforms or
        marketing initiatives. You agree to irrevocably waive (and cause to be
        waived) any claims and assertions of publicity or moral rights, or
        attribution with respect to your User Content.
      </p>
      <p>
        PETLOVERSCHAT will treat any feedback, communications, or suggestions
        you provide to PETLOVERSCHAT as non-confidential and non-proprietary.
        Thus, in the absence of a written agreement with PETLOVERSCHAT to the
        contrary, you agree that you will not submit to PETLOVERSCHAT any
        information or ideas that you consider to be confidential or
        proprietary.
      </p>

      <Typography variant="h5">Acceptable Use Policy</Typography>
      <p>
        You agree to comply with all terms set forth in our
        <a href="/acceptable-policy" target="_blank">
          {" "}
          Acceptable Use Policy
        </a>
        .
      </p>

      <Typography variant="h5">Third Party Sites and Other Users</Typography>
      <p>
        The Site may contain links to, or advertisements for, third party web
        sites (collectively, "Third Party Sites") (for example, social media
        sites such as Facebook, YouTube, Twitter, or Pinterest). Such Third
        Party Sites are not under the control of PETLOVERSCHAT and PETLOVERSCHAT
        is not responsible for any Third Party Sites. PETLOVERSCHAT provides
        links to these Third Party Sites only as a convenience and does not
        review, approve, monitor, endorse, warrant, or make any representations
        with respect to Third Party Sites. You agree that you use all Third
        Party Sites at your own risk. When you link to a Third Party Site, the
        applicable service provider's terms and policies, including privacy and
        data gathering practices govern. You should make whatever investigation
        you feel necessary or appropriate before proceeding with any transaction
        with any Third Party Site.
      </p>
      <p>
        Each Site user is solely responsible for any and all of its User
        Content. Because we do not control User Content, you acknowledge and
        agree that we are not responsible for any User Content and we make no
        guarantees regarding the accuracy, currency, suitability, or quality of
        any User Content, and we assume no responsibility for any User Content.
        Your interactions with other Site users are solely between you and such
        user. You agree that PETLOVERSCHAT will not be responsible for any loss
        or damage incurred as the result of any such interactions. If there is a
        dispute between you and any Site user, we are under no obligation to
        become involved.
      </p>
      <p>
        You hereby release us, our officers, employees, agents, affiliates,
        subsidiaries, parents, joint ventures, successors and any other
        companies under common control with us from claims, demands any and all
        losses, damages, rights, claims, and actions of any kind including
        personal injuries, death, and property damage, that are either directly
        or indirectly related to or arise out of any interactions with or
        conduct of other Site users or Third Party Sites.
      </p>

      <Typography variant="h5">Selling Conditions</Typography>
      <p>
        When selling an item for sale on PETLOVERSCHAT, you agree to comply with
        the following:
      </p>

      <ul>
        <li>
          The User Content and/or products you provide complies with all of the
          polices listed in this Agreement.
        </li>
        <li>
          You assume full responsibility for the item offered and the accuracy
          and content of its description.
        </li>
        <li>
          User Content that violates any of PETLOVERSCHAT policies may be
          modified or deleted at PETLOVERSCHAT sole discretion,
        </li>
        <li>
          You will not sell and will promptly remove all products for sale if
          any product recalled by a manufacturer or governmental agency, or if
          the sale of the product is prohibited by law or regulation, or if the
          product poses a health or safety hazard as specified by any
          governmental agency. PETLOVERSCHAT has no responsibility or liability
          for the safety or performance of any product that you list or sell
          using our Site, including any product that is subject to a recall. You
          are solely responsible for any non-conformity or defect in, or
          compliance with any public or private recall of any product you list
          or sell using our Site.
        </li>
      </ul>

      <Typography variant="h5">Buying Conditions</Typography>
      <p>
        When buying an item on PETLOVERSCHAT, you agree to comply with the
        following:
      </p>

      <ul>
        <li>
          You agree to comply with all of the polices listed in this Agreement.
        </li>
        <li>
          You are responsible for reading the full item description before
          making a bid or committing to buy,
        </li>
        <li>
          We do not transfer legal ownership of items from the seller to you.
        </li>
      </ul>

      <Typography variant="h5">Disclaimer of Warranties</Typography>

      <p>
        PETLOVERSCHAT intends for the information and data contained in the Site
        to be accurate and reliable but since the information and data have been
        compiled from a variety of sources, they are provided 'AS IS' and 'AS
        AVAILABLE'. You expressly agree that your use of the Site and any
        information contained therein is at your sole risk. PETLOVERSCHAT DOES
        NOT WARRANT THE ACCURACY, OR COMPLETENESS OF THE CONTENT PROVIDED ON
        THIS SITE OR THE PRODUCTS OR SERVICES OFFERED FOR SALE ON THIS SITE. WE
        CANNOT AND DO NOT WARRANT THAT YOUR USE OF THIS SITE WILL BE
        UNINTERRUPTED, SECURE OR ERROR FREE. Accordingly, to the extent
        permitted by applicable law, PETLOVERSCHAT EXPRESSLY DISCLAIMS ALL
        WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, IMPLIED
        WARRANTIES OF TITLE, NON-INFRINGEMENT, ACCURACY, MERCHANTABILITY AND
        FITNESS FOR A PURPOSE, AND ANY WARRANTIES THAT MAY ARISE FROM COURSE OF
        DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE. Some jurisdictions do
        not allow the disclaimer of warranties so such disclaimers may not apply
        to you.
      </p>

      <Typography variant="h5">Limitation on Liability</Typography>

      <p>
        IN NO EVENT SHALL PETLOVERSCHAT, OUR OFFICERS, EMPLOYEES, AGENTS,
        AFFILIATES, SUBSIDIARIES, PARENTS, JOINT VENTURES, SUCCESSORS AND ANY
        OTHER COMPANIES UNDER COMMON CONTROL WITH US BE LIABLE TO YOU OR ANY
        THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL,
        EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR IN
        ANY WAY RELATED TO YOUR RELATIONSHIP WITH PETLOVERSCHAT, YOUR USE OF THE
        WEB SITE, PRODUCTS, OR THIRD PARTY SITES, EVEN IF WE HAVE BEEN ADVISED
        OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE WEB SITE,
        PRODUCTS AND THIRD PARTY SITES ARE AT YOUR OWN DISCRETION AND RISK, AND
        YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
        LOSS OF DATA RESULTING THEREFROM.
      </p>

      <Typography variant="h5">Indemnity</Typography>
      <p>
        You agree to indemnify and hold PETLOVERSCHAT, our officers, employees,
        agents, affiliates, subsidiaries, parents, joint ventures, successors
        and any other companies under common control with us harmless, including
        costs and attorneys' fees, from any claim or demand made by any third
        party due to or arising out of (i) your use of the Site or Products,
        (ii) your User Content, or (iii) your violation of this Agreement.
        PETLOVERSCHAT reserves the right, at your expense, to assume the
        exclusive defense and control of any matter for which you are required
        to indemnify us and you agree to cooperate with our defense of these
        claims. You agree not to settle any matter without the prior written
        consent of PETLOVERSCHAT. PETLOVERSCHAT will use reasonable efforts to
        notify you of any such claim, action or proceeding upon becoming aware
        of it.
      </p>

      <Typography variant="h5">Arbitration Agreement</Typography>
      <p>
        The parties acknowledge that any statutory or common law claims related
        to intellectual property may require forms of equitable relief that are
        best administered by courts; accordingly, the parties agree that except
        for statutory or common law claims related to intellectual property and
        disputes that qualify for small claims court, any controversy or claim
        arising out of or relating to this Agreement or any aspect of the
        relationship between us, whether based in contract, tort, statute,
        fraud, misrepresentation or any other legal theory, will be resolved by
        arbitration administered by the American Arbitration Association ("AAA")
        in accordance with its Commercial Arbitration Rules and the
        Supplementary Procedures for Consumer Related Disputes. Judgment on the
        award rendered by the arbitrator may be entered in any federal or state
        court of competent jurisdiction located in the County of Sarasota in the
        State of Florida. For more information about arbitration, the AAA and
        the arbitration process, please consult the American Arbitration
        Association web site at www.adr.org. You agree that by entering into
        this Agreement, you and we are each waiving the right to trial by jury,
        except as otherwise stated above. Any arbitration under this Agreement
        will take place on an individual basis; class arbitrations and class
        actions are not permitted and, by entering into this Agreement, you are
        giving up the ability to participate in a class action. All issues are
        for the arbitrator to decide, except that issues relating to the scope,
        application, and enforceability of the arbitration provision are for the
        court to decide. As stated in the General section below, Florida law
        applies to any arbitration under this section, but the parties
        acknowledge that the Federal Arbitration Act governs the interpretation
        and enforcement of this provision. This agreement to arbitrate shall
        survive termination of this Agreement. This arbitration agreement does
        not preclude you from bringing issues to the attention of federal,
        state, or local agencies, and, if the law allows, they can seek relief
        against us for you.
      </p>
      <p>
        Unless you and we agree otherwise, the arbitration will take place
        either in the subscriber's county of principal residence or the County
        of Sarasota in the State of Florida. For claims of $14,000 or less, you
        can choose whether you would like arbitration carried out based only on
        documents submitted to the arbitrator, or by a hearing in person, or by
        phone.
      </p>
      <p>
        The arbitrator may award money or equitable relief in favor of only the
        individual party seeking relief and only to the extent necessary to
        provide relief warranted by that party's individual claim. To reduce the
        time and expense of the arbitration, the arbitrator will not provide a
        statement of reasons for his or her award unless requested to do so by
        both parties. Unless both you and we agree otherwise, the arbitrator may
        not consolidate more than one person's claim(s) and may not otherwise
        preside over any form of a representative or class proceeding.
      </p>

      <Typography variant="h5">General</Typography>
      <p>
        We reserve the right to make changes to our Site, this Agreement and
        other service terms, such as our Privacy Policy at any time. Each time
        you use our Site, you should visit and review the then current terms
        that apply to your transactions and use of our Site. If you do not agree
        to be bound by the then current terms, your sole remedy is not to use
        our Site. If any terms of this Agreement or other service terms are
        deeded invalid, void, or for any reason unenforceable, any such term
        shall be deemed severable and shall not affect the validity and
        enforceability of any other terms.
      </p>
      <p>
        This Agreement constitutes the entire agreement between you and us
        regarding the use of the Site and the purchase of any Products on our
        Site. Our failure to exercise or enforce any right or provision of this
        Agreement shall not operate as a waiver of such right or provision. This
        Agreement is governed by the laws of the State of Arizona without regard
        to conflict of law provisions. The section titles in this Agreement are
        for convenience only and have no legal or contractual effect. The word
        "including" means including without limitation. If any provision of this
        Agreement is, for any reason, held to be invalid or unenforceable, the
        other provisions of this Agreement will be unimpaired, and the invalid
        or unenforceable provision will be deemed modified so that it is valid
        and enforceable to the maximum extent permitted by law. This Agreement,
        and your rights and obligations herein, may not be assigned,
        subcontracted, delegated, or otherwise transferred by you without
        PETLOVERSCHAT's prior written consent, and any attempted assignment,
        subcontract, delegation, or transfer in violation of the foregoing will
        be null and void. The terms of this Agreement shall be binding upon
        assignees.
      </p>

      <Typography variant="h5">
        DISCLAIMER. USE OF THIS ADVERTISING PLATFORM IS ACKNOWLEDGMENT AND
        ACCEPTANCE OF ALL TERMS AND CONDITIONS HEREIN.{" "}
      </Typography>
      <p>
        By using PETLOVERSCHAT’s advertising platform, you acknowledge and
        accept all terms of use set forth below. If you do not accept the terms
        of use, do not use this ad center. The owners and managers of this
        website/ application may revise terms of use at any time, your use after
        such revisions acknowledges acceptance of updated terms of use. Please
        check these terms of condition frequently.{" "}
      </p>

      <Typography variant="h5">1. PROHIBITED AD CONTENT </Typography>
      <p>
        All ads to be produced, whether text, image, or video based, will be
        subject to review by the website/ application owners/ managers. This
        website/ application will strictly comply with all state and Federal
        laws. The following content restrictions will be strictly enforced. Any
        ads that violate these terms will be rejected, removed, and/or
        advertiser may be banned from further advertising on this platform.{" "}
      </p>
      <ol type="a">
        <li>
          <strong>Unlawful Products or Services.</strong> Ads must not
          constitute, facilitate, or promote products, services, or activities,
          that violate state or Federal law.
        </li>
        <li>
          <strong>Ads Targeted to Minors.</strong> Ads targeting to individuals
          under the age of eighteen (18) years must be age appropriate. Ads must
          not promote products, services, or content that illegal, harmful, or
          that exploits, misleads, or exerts undue pressure on the age group
          targeted.
        </li>
        <li>
          <strong>Discrimination.</strong> No ad may discriminate, directly or
          indirectly, or encourage discrimination on any basis, including but
          not limited to, race, ethnicity, color, national origin, religion,
          sex, sexual orientation, gender identity, family status, disability,
          or any medical and/or genetic condition.
        </li>
        <li>
          <strong>Illegal Drugs, Tobacco and Vaporizers.</strong> No ad may
          advertise or promote the use or sale of illicit or recreational drugs,
          or tobacco. Additionally, no ad may promote or facilitate the sale of
          e-cigarettes, vaporizers, or any product that simulates smoking.
        </li>
        <li>
          <strong>Adult Content.</strong> Ad may not contain nudity, suggestive
          situations and/or positions, or be overly sexual in nature.
        </li>
        <li>
          <strong>Violence and Weapons.</strong> Ad must not contain shocking or
          excessively violent content. No ad may facilitate or promote the sale
          of weapons, weapon modifications, ammunition, or explosives.
        </li>
        <li>
          <strong>Deceitful Practice.</strong> Ad must not promote or facilitate
          any individual to engage in deceitful or unlawful activity.
        </li>
        <li>
          <strong>Profanity.</strong> Ad must not contain profanity of any kind,
          which includes profane language comprised of special characters.
        </li>
        <li>
          <strong>Negative Self-Image.</strong> No ad may suggest, imply, or
          attempt to generate a negative self-image or perception.
        </li>
        <li>
          <strong>Spyware or Malware.</strong> Ad must not contain spyware,
          malware, or any code that results in a deceptive experience, or
          endangers users’ online identity, privacy, security, or equipment.
        </li>
        <li>
          <strong>Misinformation and Misleading Claims.</strong> Ads must not
          contain false or misleading information; this includes, but not
          limited to, product accreditations, approvals, certifications, and
          endorsements. No ad may make claims that create unrealistic
          expectations.
        </li>
        <li>
          <strong>Medical and/or Veterinarian Discouragement.</strong> No ad may
          include any content intended to discourage individuals from seeking
          advice, treatment, vaccinations from qualified medical or veterinarian
          professionals. Ads must not intend to discourage trusting qualified
          medical or veterinarian professionals.
        </li>
        <li>
          <strong>Online Pharmacies.</strong> At this time, this platform may
          not be used to advertise online pharmacies.
        </li>
        <li>
          <strong>Over-the-counter Medications.</strong> Ads that promote or
          facilitate the sale of over-the-counter medications must be in
          compliance with all applicable state and Federal laws, required
          industry codes, guidelines, licenses, and approvals.
        </li>
        <li>
          <strong>Treatment Facilities and Services.</strong> Ads for alcohol or
          substance addiction treatment services, programs, and facilities,
          in-person or online, including support groups and crisis hotlines are
          not permitted.
        </li>
        <li>
          <strong>Weight Loss, Cosmetic Procedures and Supplements.</strong> No
          ads promoting weight loss, products and/or services; cosmetic
          surgeries or procedures; or dietary, health, or herbal supplements may
          be targeting at users under the age of eighteen (18) years.
        </li>
        <li>
          <strong>Loans and Advances.</strong> Ads promoting short-term loans,
          including but not limited to, payday loans, cash advances, bail bonds,
          or any loan of a duration of ninety (90) days or less are not
          permitted.
        </li>
        <li>
          <strong>Implications About Personal Attributes.</strong> No ads may
          assert or imply personal attributes, including direct or indirect
          statements or implications about an individual’s race, ethnicity,
          religion, beliefs, age, sexual orientation, gender identity,
          disability, physical and/or mental health, financial status, voting
          status, affiliation with a trade union, or criminal record.
        </li>
        <li>
          <strong>Contentious Content.</strong> Ad content that exploits crises
          or includes controversial political and/or social views is prohibited.
        </li>
        <li>
          <strong>Income Opportunities.</strong> Ad content that promotes income
          opportunities must be clear, concise, and complete. Ads promoting
          business models that offer a quick profit for a small investment,
          including but not limited to, multilevel marketing opportunities is
          prohibited.
        </li>
        <li>
          <strong>Auctions.</strong> No ad may promote penny auctions, bidding
          fee auctions, or any similar model.
        </li>
        <li>
          <strong>Scams.</strong> No ad may promote or facilitate the sale of
          products and/or services using any degree of delusive practice,
          including scams to acquire money or personal information.
        </li>
        <li>
          <strong>Evading Ad Restrictions and Procedure.</strong> Attempts to
          evade PETLOVERCHAT’s advertising procedure, restrictions, terms,
          and/or review process, including but not limited to, attempts to
          disguise content or landing pages is strictly prohibited and may
          result in a permanent ban from the advertising platform.
        </li>
        <li>
          <strong>Sale of Human & Animal Parts.</strong> Ads promoting or
          facilitating the sale of human or animal body parts or bodily fluid is
          prohibited.
        </li>
        <li>
          <strong>Inciting Violence and/or Harmful Conduct.</strong> No ad
          content may assert that an individual or group of individuals are a
          threat to the safety of others, nor may ad content contain or promote
          violence, dehumanizing language, contempt, or disgust, based on race,
          ethnicity, national origin, religion, sexual orientation, gender,
          gender identity, disease/ disability, or immigration status.
          Additionally, ad content may not promote, praise, support, condone, or
          represent individuals or groups associated with violence or terrorism.
        </li>
        <li>
          <strong>Digital Currency.</strong> Ad content promoting or
          facilitating the sale and/or trading of digital currency,
          cryptocurrency, or related products is prohibited.
        </li>
      </ol>

      <Typography variant="h5">2. RESTRICTED AD CONTENT </Typography>
      <ol type="a">
        <li>
          <strong>Alcohol.</strong> Ad content that promotes, references, and/or
          facilitates the sale of alcohol must comply with all state, Federal,
          and local laws, and any required industry codes, licenses, and/or
          guidelines. Ad targeting, including but not limited, to age and
          country, must comply with PETLOVERSCHAT’s terms and conditions and any
          applicable state, Federal, and/or local laws.{" "}
          <strong>
            Ads promoting and/or facilitating the sale of alcohol, online or
            offline, in some countries, including but not limited to,
            Afghanistan, Algeria, Bahrain, Bangladesh, Belarus, Brunei, Egypt,
            Eritrea, Jordan, Kazakhstan, Kuwait, Lebanon, Libya, Malaysia,
            Mauritania, Morocco, Norway, Oman, Pakistan, Qatar, Russia, Saudi
            Arabia, Somalia, Sri Lanka, Thailand, Turkey, United Arab Emirates,
            Uzbekistan, and Yemen are strictly prohibited.
          </strong>
        </li>
        <li>
          <strong>Subscriptions.</strong> Ad content that promotes or offers
          subscription services, including but not limited to, automatic
          renewals and/or free trials, is subject to additional review and
          requirements.
        </li>
        <li>
          <strong>Financial Services.</strong> Ads promoting or facilitating the
          acquisition of credit cards, lines of credit, loans of any type, with
          accredited financial institutions, must fully disclose all material
          information, including but not limited to, any applicable fees,
          interest rates (APR percentages), and physical address of the
          institution providing the product and/or services. Credit card, lines
          of credit, loans, and insurance services may not be targeted to
          individuals under the age of eighteen (18) years.
        </li>
        <li>
          <strong>Simulated Gambling.</strong> Ads for any games that simulate
          casinos and/or gaming, where there is no ability to win or acquire
          anything of monetary value, may not be targeted to any individuals
          under the age of eighteen (18) years.
        </li>
        <li>
          <strong>Prescription Medications.</strong> Ad content promoting
          prescription medications is subject to additional review and
          requirements. No ads promoting prescription medication may be targeted
          to individuals under the age of eighteen (18) years.
        </li>
        <li>
          <strong>Lead Generation.</strong> Ads may not contain, or direct to a
          landing page that contains, lead generation questions to request the
          following information without approval from PETLOVERSCHAT:
          <p>
            1. <strong>Account Numbers.</strong> Ads cannot request any type of
            account numbers, including but not limited to, frequent flyer
            number, loyalty card number, or utility account number, without
            prior approval.{" "}
          </p>
          <p>
            2. <strong>Criminal Record.</strong> Ads cannot request any
            information related to criminal or arrest history without prior
            approval.
          </p>
          <p>
            3. <strong>Financial Details.</strong> Ads cannot request a user’s
            financial details, including but not limited to, bank account
            numbers, routing numbers, credit or debit card numbers, credit
            scores, income, net worth, or debt without prior approval.
          </p>
          <p>
            4. <strong>Government Issued ID.</strong> Ads cannot request a
            user’s Social Security Number, passport number, and/or driver’s
            license number without prior approval.
          </p>
          <p>
            5. <strong>Healthcare Details.</strong> Ads cannot request
            health-related information, including but not limited to, physical
            health, mental health, family history of medical conditions, or
            disabilities, without prior approval.
          </p>
          <p>
            6. <strong>Insurance Information.</strong> Ads cannot request
            insurance policy information without prior approval.
          </p>
          <p>
            7. <strong>Voting Status/ Political Party Affiliation.</strong> No
            ad may inquire upon a user’s political party affiliation or voting
            status.
          </p>
          <p>
            8. <strong>Religion.</strong> No ad may inquire upon a user’s
            religious affiliation.
          </p>
          <p>
            9. <strong>Race/ Ethnicity.</strong> No ad may inquire upon a user’s
            race and/or ethnicity.{" "}
          </p>
          <p>
            10. <strong>Sexual Orientation.</strong> No ad may inquire upon the
            sexual orientation, preference, or any details of a user’s sex life
            without prior approval.
          </p>
          <p>
            11. <strong>Union Membership.</strong> No ad may inquire upon a
            user’s trade union membership or affiliation without prior approval.
          </p>
          <p>
            12. <strong>Account Login Details.</strong> Ads may not request
            user’s login details, including but not limited to, usernames,
            passwords, and/or account security question answers for any existing
            accounts.
          </p>
          <p>
            g. <strong>Gaming & Gambling.</strong> Ad content promoting online
            gaming and gambling, where payment (including but not limited to
            cash and/or digital currency) is required to play to win or acquire
            anything of monetary value, requires PETLOVERSCHAT’s prior approval.
            Prior approval is required for any game where payment is required
            for play or continued play in the acquisition of a prize of monetary
            value. In addition to prior approval, ads must comply with any and
            all applicable state, Federal, and local laws, including the
            targeting of users. No ad promoting online gaming and/or gambling
            may be targeted to individuals under the age of eighteen (18) years.
            To apply for approval to advertise online gaming and/or gambling,
            complete the questionnaire and execute the required addendum.
          </p>
        </li>
      </ol>

      <Typography variant="h5">
        3. ADDITIONAL AD REQUIREMENTS & RESTRICTIONS
      </Typography>
      <ol type="a">
        <li>
          <strong>Copyright & Trademark Infringement.</strong> No ad may include
          any content that violates or infringes upon any third party’s
          copyright, trademark, privacy, publicity, or any other personal and/or
          proprietary rights.
        </li>
        <li>
          <strong>Brand Usage.</strong> Ads that direct users to PETLOVERSCHAT
          as a landing page may make limited reference to PETLOVERSCHAT for
          purposes of informing users of the ad’s destination; however, no
          modification of the PETLOVERSCHAT logo or brand shall be permitted.
          Ads not displayed on PETLOVERSCHAT and landing pages not on the
          PETLOVERSCHAT website/ application may not use any PETLOVERSCHAT
          copyrights, trademarks, or anything that could be reasonable mistaken
          for PETLOVERSCHAT’s proprietary assets. Any depictions of the
          PETLOVERSCHAT user interface must be accurate and not contain any
          modifications.
        </li>
      </ol>

      <Typography variant="h5">4. AD REVIEW PROCESS </Typography>
      <p>
        The PETLOVERSCHAT ad platform primarily utilizes automated tools to
        review ads against the above-outlined ad guidelines and restrictions.
        Every ad submitted will be reviewed prior to displaying on the
        PETLOVERSCHAT website and/or application. The ad review process will
        typically be complete within twenty-four (24) to forty-eight (48) hours
        after an ad is submitted; however, in some instances, it may require
        additional time for review. Once an ad begins displaying, it may still
        be subject to further review, and could be removed should it be
        determined that it violates any policy or restriction.
      </p>
      <ol type="a">
        <li>
          <strong>Reviewing Ads.</strong> All components of an ad will be
          reviewed, including, but not limited to, text, images, videos,
          targeting criteria, and landing pages. If any component of a submitted
          ad violates PETLOVERSCHAT’s advertising policies, then the ad will be
          rejected. If you believe your submission was rejected mistakenly, you
          may request a second review.
        </li>
        <li>
          <strong>Ad is Rejected: What Next?</strong> Advertisers may create a
          new ad or request an additional review of the ad in question.
        </li>
        <li>
          <strong>Additional Enforcement of Ad Violations.</strong> Violations
          of PETLOVERSCHAT’s ad policies and restrictions may result in a
          temporary or permanent ban from the advertising platform.
        </li>
      </ol>

      <Typography variant="h5">5. AD TECHNICAL REQUIREMENTS </Typography>
      <ol type="a">
        <li>
          <strong>Low Quality Content or Misleading Headlines.</strong> Ad
          content must not contain misleading ad positioning or over
          sensationalized text, images, video, or commands to coerce users to
          interact with ad. Ads may not link to unrelated landing pages. Low
          quality imagery or video will be rejected. PETLOVERSCHAT reserves the
          right to set quality requirements for any and all types of ads.
        </li>
        <li>
          <strong>Broken Links and Missing Landing Pages.</strong> All ads being
          displayed on PETLOVERSCHAT must have functioning destination links and
          functioning landing pages. If advertiser removes a landing page or
          alters a destination link, it is advertiser’s responsibility to edit
          the ad accordingly. Ads with broken links or non-functioning/
          non-existent landing pages will be removed.
        </li>
        <li>
          <strong>Placement and Positioning.</strong> All ad content, including
          but not limited to, text, images, video, or other media, must be
          relevant and appropriate to whatever subject, including but not
          limited to products or services, being advertised. Ads must clearly
          and accurately represent the subject, including but not limited to
          products or services, being advertised. Ads must be linked to relevant
          and appropriate landing pages; additionally, landing pages may not
          offer, promote, or facilitate the sale of any product or service that
          is prohibited by PETLOVERSCHAT’s policies and restrictions.
        </li>
        <li>
          <strong>Video Ads.</strong> Video and dynamic types of advertisements
          must comply with all PETLOVERSCHAT policies. Videos that are overly
          disruptive, such as strobing or flashing imagery, will be rejected. No
          ad shall utilize disruptive tactics to coerce users to interact with
          ad. Entertainment-related ads, such as trailers or promotions for TV,
          movies, and/or music, shall not include drugs and alcohol use,
          profanity, violence, gore, or other adult content. Furthermore,
          entertainment ads that features any adult content may not be targeted
          to individuals under the age of eighteen (18) years.
        </li>
        <li>
          <strong>Targeting Policies.</strong> Advertisers may not utilize
          PETLOVERSCHAT’s targeting options to discriminate, harass, demean,
          provoke, or dehumanize any person or group. Predatory or exploitative
          targeting practices are strictly prohibited and may result in a
          permanent ban from PETLOVERSCHAT’s ad platform.
        </li>
      </ol>

      <Typography variant="h5">6. DATA USAGE RESTRICTIONS </Typography>
      <ol type="a">
        <li>
          Use of the PETLOVERSCHAT ad platform is formal acknowledgment and
          express acceptance of a duty to limit access of ad data received or
          derived from this platform to only those acting on the advertiser’s
          behalf; acceptance of a duty to ensure that all ad data is protected
          regardless of who has access to such ad data; acceptance of a duty to
          keep all ad data confidential and secure.
        </li>
        <li>
          Ad data received or acquired through the PETLOVERSCHAT ad platform may
          only be used for purposes of improving performance of ads on the
          PETLOVERSCHAT ad platform and not to be used for any other purpose or
          on any other platform.
        </li>
        <li>
          Ad data received or acquired through the PETLOVERSCHAT ad platform
          shall not be transferred, added, or utilized for any other ad network,
          ad exchange, data broker, or any other advertising service.
        </li>
      </ol>

      <Typography variant="h5">
        7. IMPORTANT INFORMATION FOR ADVERTISERS{" "}
      </Typography>
      <p>
        Use of the PETLOVERSCHAT ad platform serves as formal acknowledgement
        and acceptance of the following imposed duties:{" "}
      </p>
      <ol type="a">
        <li>
          The policies, rules, restrictions, terms, and conditions apply to any
          and all advertising on PETLOVERSCHAT’s website and social application.
        </li>
        <li>
          It is the advertiser’s responsibility to understand and comply with
          any and all applicable state, Federal, or local laws. Advertisers who
          fail to comply with such laws may face a permanent ban of any
          advertising on this platform. Additionally, PETLOVERSCHAT will comply
          with lawful discovery requests, if an advertiser’s violation of
          applicable state, Federal, or local laws results in prosecution.
        </li>
        <li>
          No personal and/or sensitive data shall be used by PETLOVERSCHAT for
          the purposes of ad targeting. The criteria you use for ad targeting do
          not represent the beliefs, values, or behavior of PETLOVERSCHAT users.
        </li>
        <li>
          Once an ad is approved through the PETLOVERSCHAT ad approval process,
          that ad is public information. The ad may be shared and/or displayed
          to users outside your original targeted criteria. Ad content may be
          archived for a period of seven (7) years from the completion of your
          ad campaign. PETLOVERSCHAT may provide your ad, including but not
          limited to content, targeting, and other information associated with
          your ad use on this ad platform, to law enforcement or other
          government agency if PETLOVERSCHAT believes that providing such
          information would aid in a lawful investigation.
        </li>
        <li>
          If you are an advertising agency or advertising on behalf of another
          entity, clients, or advertisers, must be possess different, unique ad
          accounts and, under no circumstances, may a client or advertiser be
          changed on an established PETLOVERSCHAT ad account. Use of this ad
          platform is formal acknowledgment and agreement that each and every
          advertiser is to comply with all policies, rules, restrictions, terms,
          and conditions of PETLOVERSCHAT ad platform.
        </li>
        <li>
          Under no circumstance may any individual or business entity help, aid,
          or contribute to an effort to circumvent, evade, or otherwise avoid
          enforcement of PETLOVERSCHAT’s policies, rules, restrictions, terms,
          and conditions.
        </li>
        <li>
          PETLOVERSCHAT reserves the right to approve, disapprove, or remove any
          ad by any advertiser at any time with or without notice for any
          reason, at our sole discretion. Ad content that negatively impacts our
          relationship with users or is not consistent with our competitive
          position, interests, or company mission will be removed, with or
          without notice, at our sole discretion.
        </li>
        <li>
          All policies, rules, restrictions, terms, and conditions are subject
          to change at any time, with or without notice, at our sole discretion.
          Your continued use of this ad platform, after such change, is
          acknowledgement and acceptance of such changes.
        </li>
      </ol>

      <Typography variant="h5">8. AD SPEND & PAYMENT </Typography>
      <p>
        It is advertiser’s responsibility to frequently review ad performance,
        ad spend, and ad targeting specifications. PETLOVERSCHAT has exercised
        requisite due diligence in selecting ©Stripe for payment processing,
        including the acquiring, managing, and maintaining of advertiser’s
        payment information and processing ad spend transactions. It is
        incumbent upon advertiser to review ©Stripe terms and conditions.
        PETLOVERSCHAT is not responsible for any alleged intentional or
        negligent acts of ©Stripe or its directors, officers, managers,
        employers, or vendors.
      </p>

      <Typography variant="h5">9. INDEMNIFICATION</Typography>
      <p>
        Advertiser shall be responsible for the accuracy, completeness of
        information concerning advertiser’s company, products, services, and
        claims. Advertiser will indemnify, defend, and hold this website/
        application harmless from any damages, loss, cost, claim, or liability
        (including reasonable attorney fees and legal costs of enforcing this
        indemnification clause) arising out of: (i) advertiser’s violation of
        any of the above terms; (ii) advertiser’s negligence or willful
        misconduct related to the advertising to, acquisition of, handling of,
        and maintaining of users acquired through use of this website’s ad
        center.
      </p>

      <Typography variant="h5">Contact Us</Typography>
      <p>
        If you have questions about our Terms of Use or Privacy Policy, contact
        us by:
      </p>

      <ul>
        <li>E-mail: info@petloverschat.com</li>
      </ul>
    </div>
  );
};

export default TermsCondition;
