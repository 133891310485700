import { Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import Logo from "../../images/logo-petlover.png";
import Banner from "../../images/login-banner.png";

import { CommonButton } from "../../stories/Button";
import { UserAuthStyles } from "./UserAuth";
import { UserCommonStyles } from "../../styles/CommonStyles";
import { resetPassword } from "../../redux/action-creators";

import { toast } from "../../utils/toastsMessage";
import { resetPassWordValidationSchema } from "../../utils/validationSchema";
import { RoutingLinks } from "../../utils/constants";
import { IForgotPassword } from "../../interfaceModules/IUserInterface";

const ResetPassword = (props: any) => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = UserAuthStyles();
  const dispatch = useDispatch();
  const [disable, setdisable] = useState(false)

  const email: string = props.location.state.email;

  /**
   * Using useForm hook to handle change events, validations and form submission
   */
  const {
    handleSubmit,
    register,

    formState: { errors },
  } = useForm<IForgotPassword>({
    resolver: yupResolver(resetPassWordValidationSchema(translation)),
  });

  /**
   * Submit the form and show the pop-up as toast
   * @param data <IForgotPassword>
   */
  const onSubmit = async (data: IForgotPassword) => {
    setdisable(true)

    const response: any = await dispatch(resetPassword(data));
    if (response?.data?.success === false) {
      toast.error(translation(response.data.message));
      setdisable(false)
    } else {
      props.history.push({
        pathname: "/",

      })
      setdisable(false)

      toast.success(translation(response.data.message));
    }
  };

  return (
    <>
      <Box>

        <Grid container>


          <Grid item md={5}>
            <Box
              className={`${commonStyle.flexColumn} ${styleClasses.loginSide}`}
            >
              <img
                src={Logo}
                alt={translation("log_banner")}
                className={styleClasses.logoSize}
              />
              <img
                src={Banner}
                alt={translation("banner")}
                className={commonStyle.w100}
              />
            </Box>
          </Grid>
          <Grid item md={7}>
            <Box>
              <form
                className={styleClasses.authForm}
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <Box className={styleClasses.formGroup}>
                  <Typography variant="h5">Forgot Password</Typography>
                  <Box className={commonStyle.commonInput}>
                    <TextField
                      {...register("email")}
                      variant="outlined"
                      value={email}
                      inputProps={{ readOnly: true }}
                      className={commonStyle.w100}

                    />
                    <span className={commonStyle.errorMsg}>
                      {errors.email?.message}
                    </span>
                  </Box>


                  <Grid item md={6}>
                    <Box className={commonStyle.commonInput}>
                      <TextField
                        {...register("password")}
                        variant="outlined"
                        type="password"
                        label={translation("choose_password")}
                        className={commonStyle.w100}
                      />
                      {errors && errors.password && (
                        <span className={commonStyle.errorMsg}>
                          {errors.password.message}
                        </span>
                      )}
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box className={commonStyle.commonInput}>
                      <TextField
                        {...register("confirm_password")}
                        variant="outlined"
                        type="password"
                        label={"Confirm Password"}
                        className={commonStyle.w100}
                      />
                      {errors && errors.confirm_password && (
                        <span className={commonStyle.errorMsg}>
                          {errors.confirm_password.message}
                        </span>
                      )}
                    </Box>
                  </Grid>


                  <Box className={`${commonStyle.w100} ${commonStyle.orangeBtn}`}>
                    <CommonButton
                      label={translation("continue")}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </Box>

                  <Box className={styleClasses.orText}>
                    <Typography>{translation("or_continue_with")}</Typography>
                  </Box>

                  <ul
                    className={`${styleClasses.socialLogin} ${commonStyle.removeListStyle}`}
                  >





                  </ul>

                  <Box
                    className={`${styleClasses.authDescFooter} ${commonStyle.textCenter}`}
                  >
                    <Typography>
                      {translation("not_have_an_account")}{" "}
                      <Link to={RoutingLinks.signup}>
                        {translation("signup")}
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ResetPassword;
