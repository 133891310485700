import { makeStyles } from "@mui/styles";

export const HeaderStyles = makeStyles((theme) => {
  return {
    header: {
      backgroundColor: "#FFFFFF",
      position: "sticky",
      top: 0,
      zIndex: 4,
      width: "100%",
    },
    logo: {
      "& img": {
        width: "100%",
        maxWidth: "200px",
      },
    },
    headerMenu: {
      "& a": {
        color: "#01B4BC",
        display: "inline-block",
        padding: "25px",
        position: "relative",
        "& svg": {
          fontSize: "30px",
        },
        "&:hover": {
          backgroundColor: "#01B4BC",
          color: "#fff",
        },
      },
    },

    notifyNumber: {
      position: "absolute",
      top: "2px",
      right: "22px",
      width: "20px",
      height: "20px",
      borderRadius: "100px",
      backgroundColor: "#D95557",
      color: "#fff",
    },
    headerUser: {
      textDecoration: "none",
      "& img": {
        width: "50px",
        height: "50px",
        minWidth: "50px",
      },
      "& p": {
        fontSize: "14px",
        color: "#01b4bc",
        fontWeight: "500",
      },
    },
    searchListResult: {
      position: "absolute",
      top: "72px",
      left: "20px",
      backgroundColor: "#fff",
      minWidth: "360px",
      textAlign: "left",
      border: "1px solid #ddd",
      padding: "15px",
      borderRadius: "5px",
      maxHeight: "170px",
      overflow: "auto",
      zIndex: 1,
      "& p": {
        textAlign: "left",
        marginLeft: "10px",
      },
      "& a": {
        borderBottom: "1px solid #ddd",
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "10px 0",
      },
      "& img": {
        width: "70px",
        height: "50px",
        objectFit: "cover",
        objectPosition: "center",
      },
    },

    settingPopoverList: {
      width: "200px",
      "& li": {
        borderBottom: "1px solid #ccc",
        "& a": {
          color: "#000",
          textDecoration: "none",
          display: "block",
          padding: "10px 20px",
          fontSize: "14px",
        },
      },
    },
  };
});
