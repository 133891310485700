import { Grid, IconButton, TextField, Typography } from "@mui/material";
import {  Google } from "@mui/icons-material";
import { Box } from "@mui/system";
import  {
  useState,
  
} from "react";

import { Link, RouteComponentProps } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import Logo from "../../images/logo-petlover.png";
import Banner from "../../images/login-banner.png";

import { UserAuthStyles } from "./UserAuth";
import { UserCommonStyles } from "../../styles/CommonStyles";
import { CommonButton } from "../../stories/Button";

import GoogleLogins from "../../components/socialLogin/googleLogin";
import FacebookLogins from "../../components/socialLogin/facebookLogin";

import { SignInAction } from "../../redux/action-creators/index";

import { toast } from "../../utils/toastsMessage";
import { ILoginState } from "../../interfaceModules/IUserInterface";
import { RoutingLinks } from "../../utils/constants";
import { loginValidationSchema } from "../../utils/validationSchema";

const Login = (props: RouteComponentProps) => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = UserAuthStyles();

  const dispatch = useDispatch();
  const [disable,setdisable]=useState(false)
  /**
   * Using useForm hook to handle change events, validations and form submission
   */
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ILoginState>({
    resolver: yupResolver(loginValidationSchema(translation)),
  });

  /**
   * Submit the form, if successful, then sets the userData in localStorage
   * @param data
   * @returns toast
   */
  const onSubmit = async (data: ILoginState) => {
    const { email, password } = data;
      setdisable(true)
    const response: any = await dispatch(SignInAction({ email, password }));    

    if (response.email) {
      localStorage.setItem("userId", response.id);
      localStorage.setItem("userData", JSON.stringify(response));
      localStorage.setItem("token", response.token);
      setdisable(false);

      if(response.breed.length===0 || response.address.length===0 ){
        props.history.push(`${RoutingLinks.editProfile}${response.id}`);
      }else{
        props.history.push(RoutingLinks.home);
      }

      
    } else if (response.needToVerify) {
      setdisable(false)

      props.history.push({
        pathname: RoutingLinks.verifyEmail,
        state: { email: email },
       });
       setdisable(false)

      return toast.error(translation(response.error,{hideAfter:0}));
    } else if (response.error){ 
      setdisable(false)
      return toast.error(translation(response.error,{hideAfter:0}))};
       
  };
  return (
    <Box>
      <Grid container>
        <Grid item md={5}>
          <Box
            className={`${commonStyle.flexColumn} ${styleClasses.loginSide}`}
          >
            <img
              src={Logo}
              alt={translation("log_banner")}
              className={styleClasses.logoSize}
            />
            <img
              src={Banner}
              alt={translation("banner")}
              className={commonStyle.w100}
            />
          </Box>
        </Grid>

        <Grid item md={7}>
          <Box>
            <form
              className={styleClasses.authForm}
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <Box className={styleClasses.formGroup}>
                <Typography variant="h5">{translation("login")}</Typography>
                <Box className={commonStyle.commonInput}>
                  <TextField
                    {...register("email")}
                    type="email"
                    variant="outlined"
                    label={translation("email")}
                    className={commonStyle.w100}
            
                  />
                  <span className={commonStyle.errorMsg}>
                    {errors.email?.message}
                  </span>
                </Box>
                <Box className={commonStyle.commonInput}>
                  <TextField
                    {...register("password")}
                    type="password"
                    variant="outlined"
                    label={translation("password")}
                    className={commonStyle.w100}
                  />
                  <span className={commonStyle.errorMsg}>
                    {errors.password?.message}
                  </span>
                </Box>

                <Typography
                  className={`${commonStyle.mb15} ${commonStyle.textRight}`}
                >
                  <Link
                    to='/forgot-password'
                    className={`${styleClasses.forgotLink} ${commonStyle.orange}`}
                  >
                    {translation("forgot_password")}
                  </Link>
                </Typography>

                <Box className={`${commonStyle.w100} ${commonStyle.orangeBtn}`}>
                  <CommonButton
                    label={translation("continue")}
                    onClick={handleSubmit(onSubmit)}
                    disabled={disable}
                  />
                </Box>

                <Box className={styleClasses.orText}>
                  <Typography>{translation("or_continue_with")}</Typography>
                </Box>

                <ul
                  className={`${styleClasses.socialLogin} ${commonStyle.removeListStyle}`}
                >
                  <li>
                    <IconButton
                      className={`${commonStyle.facebookBtn} ${commonStyle.socialBtnCustom} ${commonStyle.bgBlue}`}
                    >
                      <FacebookLogins />
                    </IconButton>
                  </li>

                  <li>
                    <IconButton
                      className={`${commonStyle.iconBtn} ${commonStyle.appleBtn} ${commonStyle.socialBtnCustom} ${commonStyle.bgRed}`}
                    >
                      <div className={commonStyle.googleLogin}>
                        <GoogleLogins />
                      </div>
                      <Google />
                    </IconButton>
                  </li>
                </ul>

                <Box
                  className={`${styleClasses.authDescFooter} ${commonStyle.textCenter}`}
                >
                  <Typography>
                    {translation("not_have_an_account")}{" "}
                    <Link to={RoutingLinks.signup}>
                      {translation("signup")}
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
