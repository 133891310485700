import { Route, Redirect, withRouter } from "react-router-dom";

function PrivateGuard(props: any) {
  const dontShowAfterLogin = [
    "/signup",
    "/login",    
    "/verify-email",  
    "/",
    "/forgot-password",
    "/reset-password",


  ];
  const route = props.location?.pathname;
  const checkUserLoginStatus = () => {
    return localStorage.getItem("token");
  }; 

  return (
    <>
      {checkUserLoginStatus() ? (
        route === "/" ? (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: props.location },
            }}
          />
        ) : !dontShowAfterLogin.includes(route) ? (
          <Route {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: props.location },
            }}
          />
        )
      ) : dontShowAfterLogin.includes(route) ? (
        <Route {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )}
    </>
  );
}

export default withRouter(PrivateGuard);
