import { ActionType } from "../action-types/index";

export const initialState = {
  notificationList: [],
  notificationCount: 0
};

/**
 * user information stored in global state of redux
 * @param state
 * @param action
 * @returns
 */
export const notificationReducer = (state: object = initialState, action: any) => {
  switch (action.type) {
    case ActionType.Notification:
      return {
        ...state,
        notificationList: action.payload,
      };
    case ActionType.NotificationCount:
      return {
        ...state,
        notificationCount: action.payload
      };
    default:
      return state;
  }
};
