import axios from "axios";
const BASE_URL: string = process.env.REACT_APP_BASE_URL as string;
const axiosInstance = axios.create({ baseURL: BASE_URL });

// errorComposer will compose a handleGlobally function
const errorComposer = (error) => {
  const statusCode = error.response ? error.response.status : null;
  let message = "Something went wrong.";
  if (statusCode === 404) {
    message = "The requested resource does not exist or has been deleted";
  }

  if (statusCode === 401) {
    message = "Please login to access this resource";
  }
  if (statusCode === 400) {
    message = error.response;
  }
  return message;
};

//Handles the error
const errHandling = (error) => {
  return new Promise((resolve, reject) => {
    resolve(errorComposer(error));
  });
};

//Handles the request
const reqHandling = (config) => {
  return new Promise((resolve, reject) => {
    const obj = { ...config };
    const token = localStorage.getItem("token");
    if (token) obj.headers["Authorization"] = token;
    resolve(obj);
  });
};

const resHandling = (response) => response;
axiosInstance.interceptors.request.use(reqHandling, errHandling);
axiosInstance.interceptors.response.use(resHandling, errHandling);

export default axiosInstance;
