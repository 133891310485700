import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { commonReducer } from "./coomonReducer";
import { productReducer } from "./productReducer";
import { notificationReducer } from "./notificationReducer";
import { postReducer } from "./postReducer";
import { rightSideBarReducer } from "./rightSideBarReducer";

/**
 * All the reducers will connect with react app and manages the global state
 */
const appReducer = combineReducers({
  authReducer,
  commonReducer,
  productReducer,
  notificationReducer,
  postReducer,
  rightSideBarReducer,
});

export default appReducer;
