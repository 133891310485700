import React, { useState } from "react";

import { Grid, TextField, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";

import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import Logo from "../../images/logo-petlover.png";
import Banner from "../../images/login-banner.png";
// eslint-disable-next-line
import { CommonButton } from "../../stories/Button";
import { UserAuthStyles } from "./UserAuth";
import { UserCommonStyles } from "../../styles/CommonStyles";
import { verifyOtp, updateOtp } from "../../redux/action-creators/verifyEmail";

import { toast } from "../../utils/toastsMessage";
import { IverifyEmail } from "../../interfaceModules/IPetsInteface";
import { verifyEmailValidationSchema } from "../../utils/validationSchema";
import { RoutingLinks } from "../../utils/constants";

const VerifyEmail = (props: any) => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = UserAuthStyles();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const [showVerifyEmailButtom, setShowVerifyEmailButtom] = useState(false);

  const email: string = props.location.state.email;

  /**
   * Method resends the otp
   */
  const newOtp = async () => {
    setDisable(true);
    const response: any = await dispatch(updateOtp({ email }));
    if (response?.data?.success) {

      toast.success(translation(response.data.message));
      setTimeout(() => {
        setDisable(false)
      }, 2000);
    }
    else {
      toast.error(translation(response.data.message));
      setTimeout(() => {
        setDisable(false)
      }, 2000);
    }
  };

  /**
   * Using useForm hook to handle change events, validations and form submission
   */
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IverifyEmail>({
    resolver: yupResolver(verifyEmailValidationSchema(translation)),
  });

  /**
   * Submit the form and show the pop-up as toast
   * @param data <IverifyEmail>
   */
  const onSubmit = async (data: IverifyEmail) => {
    setShowVerifyEmailButtom(true);
    const response: any = await dispatch(verifyOtp(data));
    if (response?.data?.success === false) {
      toast.error(translation(response.data.message));
    } else {
      if (props.location.state.from === "/forgot-password") {
        // console.log("caseeeeeeee1");
        props.history.push({
          pathname: "/reset-password",
          state: {
            email: email,
            from: "/reset-password",
          },
        });
      } else {
        props.history.push("/");
      }

      toast.success(translation(response.data.message));
    }
    setShowVerifyEmailButtom(false);
  };

  return (
    <Box>
      <Grid container>
        <Grid item md={5}>
          <Box
            className={`${commonStyle.flexColumn} ${styleClasses.loginSide}`}
          >
            <img
              src={Logo}
              alt={translation("log_banner")}
              className={styleClasses.logoSize}
            />
            <img
              src={Banner}
              alt={translation("banner")}
              className={commonStyle.w100}
            />
          </Box>
        </Grid>

        <Grid item md={7}>
          <Box>
            <form
              className={styleClasses.authForm}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box className={styleClasses.formGroup}>
                <Typography variant="h5">
                  {translation("Verification")}
                </Typography>
                <Box className={commonStyle.commonInput}>
                  <TextField
                    {...register("email")}
                    variant="outlined"
                    className={commonStyle.w100}
                    value={email}
                  />
                  <span className={commonStyle.errorMsg}>
                    {errors.email?.message}
                  </span>
                </Box>
                <Box className={commonStyle.commonInput}>
                  <TextField
                    {...register("otp")}
                    variant="outlined"
                    label={translation("otp")}
                    className={commonStyle.w100}
                  />

                  <span className={commonStyle.errorMsg}>
                    {errors.otp?.message}
                  </span>
                </Box>
                <Box className={commonStyle.flexCenter}>
                  <Box
                    className={`${commonStyle.w100} ${commonStyle.orangeBtn}`}
                  >
                    <Button variant="text" onClick={handleSubmit(onSubmit)}
                      disabled={showVerifyEmailButtom}>Verify</Button>
                  </Box>
                </Box>

                <Box className={styleClasses.resendTextOtp}>
                  <Button variant="text" onClick={() => newOtp()}
                    disabled={disable}>Resend OTP</Button>
                </Box>            

                <Box
                  className={`${styleClasses.authDescFooter} ${commonStyle.textCenter}`}
                >
                  <Typography>
                    {translation("not_have_an_account")}{" "}
                    <Link to={RoutingLinks.signup}>
                      {translation("signup")}
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VerifyEmail;
