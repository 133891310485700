import React from "react";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { UserCommonStyles } from "../../styles/CommonStyles";
import { HeaderStyles } from "../../styles/Header";

import Logo from "../../images/logo-petlover.png";

const PrivacyPolicy: React.FC = () => {
  const commonStyle = UserCommonStyles();
  const styleClasses = HeaderStyles();

  return (
    <div className={commonStyle.p20}>
      <Box className={`${commonStyle.mb20} ${styleClasses.logo}`}>
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
      </Box>
      <h3>SCOPE OF THIS&nbsp;NOTICE</h3>
      <p>Last updated: 02/14/2022</p>
      <br />
      <p>
        Please read this privacy notice (“<strong>Notice</strong>”) carefully to
        understand our policies and practices regarding your personal data and
        how we will treat it. This Notice applies to individuals who interact
        with PETLOVERSCHAT as users (“<strong>you</strong>”) on the sites and
        platforms on which it appears. This Notice explains how your personal
        data (which some jurisdictions may refer to as “personal information”)
        is collected, used, and disclosed by PETLOVERSCHAT and third parties
        acting on our behalf (collectively “<strong>PETLOVERSCHAT</strong>”, “
        <strong>We</strong>”, “<strong>Us</strong>” “<strong>our</strong>”) on
        the sites and platforms on which this policy appears. It also tells you
        how you can access and update your personal data and make certain
        choices about how your personal data is used.
      </p>
      <p>
        This Notice covers both our online and offline data collection
        activities where this policy is provided to you or is otherwise
        referenced. This includes personal data that We collect through our
        various channels such as websites, apps, third-party social networks,
        consumer affairs, points of sale, and events where this policy is
        provided to you. Please note that We might combine personal data from
        different sources (website, offline event), including by combining
        personal data that was originally collected by different PETLOVERSCHAT
        entities or PETLOVERSCHAT partners. We also may combine data from third
        parties with data We already have.
      </p>

      <h3>PERSONAL DATA THAT WE COLLECT ABOUT YOU</h3>
      <p>
        We collect various types of information from you, as described below.
      </p>
      <ul>
        <li>
          <strong>Contact Data:</strong>&nbsp;Any data you provide to Us that
          would allow Us to contact you, such as your name, postal address,
          e-mail address, social network details, or phone number.
        </li>
        <li>
          <strong>Account Login Data:</strong>&nbsp;Any data that is required to
          give you access to your specific account profile. Examples include
          your login ID/email address, screen name, password in unrecoverable
          form, and/or security question and answer.
        </li>
        <li>
          <strong>Device Data:</strong>&nbsp;Data about the computer system or
          other technological device that you use to access one of our websites
          or apps, such as the Internet protocol (IP) address used to connect
          your computer or device to the Internet and other online identifiers.
          If you access one of the sites or apps subject to this policy via a
          mobile device such as a smartphone, the collected data will also
          include, where permitted, your phone’s unique device ID, advertising
          ID, and other similar mobile device data.
        </li>
        <li>
          <strong>Demographic Data:</strong>&nbsp;Any data that describes your
          demographic or behavioral characteristics. Examples might include your
          gender, geographic location (e.g., ZIP code), favorite products,
          hobbies and interests, and household or lifestyle data. This may
          include data about your health, medical conditions, nutrition, and
          exercise habits and goals. It may include data about your children’s
          genders, feeding styles, birthdates, or expected due dates. It may
          also include data about work status or professional credentials. It
          may include data collected when you inquire about adopting a pet
          through a PETLOVERSCHAT member adoption organization, or create a
          saved pet search alert. In some cases, this could include data that
          you give Us about someone else. For example, if you provide a friend’s
          email address for a tell-a-friend program. It may also include data
          about your pets and pet preferences. If you are a health care
          professional, We may collect data about your practice.
        </li>
        <li>
          <strong>Third-Party Social Network Data:</strong>&nbsp;Any data that
          you share publicly on a third-party social network or data that is
          part of your profile on a third-party social network (such as
          Facebook) and that you allow the third-party social network to share
          with Us. Examples include your basic account data (e.g., name, email
          address, gender, current city, profile picture, user ID, list of
          friends, etc.) and any other additional data or activities that you
          permit the third-party social network to share. We receive your
          third-party social network profile data (or parts of it) every time
          you download or interact with a web application on a third-party
          social network such as Facebook, every time you use a social
          networking feature that is integrated within one of our sites (such as
          Facebook Connect), or every time you interact with Us through a
          third-party social network. To learn more about how your data from a
          third-party social network is obtained by us, or to opt out of sharing
          such social network data, please visit the website of the relevant
          third-party social network.
        </li>
        <li>
          <strong>Site Usage Data:</strong>&nbsp;This includes information about
          how you use our websites and our other communications. For example, as
          you navigate through and interact with our websites/apps or emails, we
          use automatic data collection technologies to collect certain data
          about your device(s) and your actions. This includes data such as
          which links you click on, which pages or content you view and for how
          long, and other similar data and statistics about your interactions,
          such as content response times, download errors, and length of visits
          to certain pages, as well as operating system type and web browser
          type and version. We may also visually record your interactions with
          our website, including your mouse clicks, movement, scrolling, and
          navigation through our website.
        </li>
        <li>
          <strong>Feedback Data:</strong>&nbsp;Any information that you share
          with Us about your experience of using our products and services. This
          includes any content that you create and then share with Us on
          third-party social networks or one of our websites or apps. Examples
          include photos, videos, personal stories, or other similar media or
          content. This may also include information you provide during your
          conversations with consumer affairs.
        </li>
        <li>
          <strong>Geolocation Data:</strong>&nbsp;We may collect information
          about your location. This may include your precise location.
        </li>
        <li>
          <strong>Inferences:</strong>&nbsp;We may draw inferences from the data
          We collect from and about you to create a profile reflecting your
          preferences, characteristics, and behavior.
        </li>
      </ul>

      <h3>HOW WE COLLECT PERSONAL DATA ABOUT YOU</h3>
      <p>
        <strong>
          We collect personal data directly from you, when you choose to provide
          it to us.{" "}
        </strong>
        &nbsp; For example, we collect data when you place an order with us. We
        collect data when you register on one of our websites or apps. We
        collect data when you become a member of a loyalty program. We collect
        data when you sign up for our emails. We collect data from printed or
        digital registrations and similar forms that We collect via, for
        example, postal mail, in-store demos, contests, and other promotions or
        events. We collect data when you inquire about adopting a pet through a
        PETLOVERSCHAT member adoption organization or create a saved pet search
        alert. We collect data if you fill out a survey or use other tools on
        your websites or apps. We also collect data if you contact Us through
        our websites or apps, via email, or through social media.
      </p>

      <p>
        <strong>We collect data from you passively.</strong>&nbsp;For example,
        we use tracking tools like browser cookies and web beacons. We do this
        in our digital platforms and in emails that We send to you. We collect
        data about users over time when you use this website. This includes
        usage and browser data. We may have service providers or other third
        parties collect data this way. We also collect data from our mobile
        apps.
      </p>

      <p>
        <strong>We get data about you from other sources.</strong>&nbsp;For
        example, our affiliates and business partners may give Us data about
        you. We may receive data from companies who compile information about
        shoppers and their preferences. Social media platforms may also give Us
        data about you. We may get data about your interactions with our ads on
        third-party sites.
      </p>

      <h3>PERSONAL DATA OF CHILDREN</h3>
      <p>
        We do not knowingly solicit or collect personal data from children below
        the age of 18. If you are a parent or legal guardian and think that your
        child under 18 has given Us data, you can contact Us in writing or by
        email as provided below under the section titled CONTACT. Please mark
        your inquiries “COPPA Information Request.”
      </p>

      <h3>USES FOR YOUR PERSONAL DATA</h3>
      <p>
        The following paragraphs describe the various purposes for which We
        collect and use your personal data, and the different types of personal
        data that are collected for each purpose. Please note that not all of
        the uses below will be relevant to every individual.
      </p>

      <ul>
        <li>
          <strong>Consumer service.</strong>&nbsp;We use your personal data for
          consumer service purposes. Consumer service activities could be
          responding to your inquiries or complaints.
        </li>
        <li>
          <strong>Contests, marketing, and other promotions.</strong>&nbsp;We
          may use your personal data to provide you with information about goods
          or services (e.g., marketing communications or campaigns or
          promotions). This can be done via email, ads, SMS, phone calls, and
          postal mailings to the extent permitted by applicable laws. Some of
          our campaigns and promotions are run on third-party websites and/or
          social networks. For more information about our contests and other
          promotions, please see the official rules or details posted with each
          contest/promotion. We may use your friend’s email address to send them
          information you request through a “tell-a-friend” feature.
        </li>
        <li>
          <strong>Third-party social networks.</strong>&nbsp;We use your
          personal data when you interact with third-party social networking
          features, such as “Like” functions, to serve you with advertisements
          and engage with you on third-party social networks. You can learn more
          about how these features work and the profile data that We obtain
          about you, and find out how to opt out by reviewing the privacy
          notices of the relevant third-party social networks.
        </li>
        <li>
          <strong>
            Other general purposes (e.g. internal or market research, analytics,
            security).
          </strong>
          &nbsp;In accordance with applicable laws, we use your personal data
          for other general business purposes, such as maintaining your account,
          conducting internal or market research, and measuring the
          effectiveness of advertising campaigns. This also includes improving
          our websites and services. We reserve the right, if you have Nestlé
          accounts, to reconcile those accounts into one single account. We also
          use your personal data for management and operation of our
          communications, IT, and security systems.
        </li>
      </ul>

      <h3>DATA PROTECTION AND RETENTION</h3>
      <p>
        <strong>
          We use standard security measures as required by applicable law.
        </strong>
        &nbsp;The transmission of information via the Internet is,
        unfortunately, not completely secure and despite our efforts to protect
        your personal data, we cannot guarantee the security of the data during
        transmission through our websites/apps. It is important that you also
        play a role in keeping your personal data safe and secure. When signing
        up for an online account, please be sure to choose an account password
        that would be difficult for others to guess and never reveal your
        password to anyone else. You are responsible for keeping this password
        confidential and for any use of your account. If you use a shared or
        public computer, never choose to have your login ID/email address or
        password remembered and make sure to log out of your account every time
        you leave the computer. You should also make use of any privacy settings
        or controls We provide you in our websites/apps.
      </p>

      <p>
        <strong>Retention of personal data.</strong>&nbsp;We keep personal data
        as long as it is necessary or relevant for the practices described in
        this Notice. We also keep personal data as otherwise required by law.
      </p>

      <h3>
        YOUR CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR&nbsp;PERSONAL DATA
      </h3>
      <p>
        We strive to provide you with choices regarding the personal data that
        you provide to Us. The following mechanisms give you the following
        control over your personal data:
      </p>

      <p>
        <strong>Advertising, marketing, and promotions.</strong>&nbsp;You can
        opt out of marketing emails by following the instructions provided in
        each such communication. Please note that even if you opt out from
        receiving marketing communications, you will still receive transactional
        communications from Us, such as order or other transaction
        confirmations, notifications about your account activities (e.g.,
        account confirmations, password changes, confirmation of pet adoption
        inquiry and submission, etc.), and other important non-marketing-related
        announcements.
      </p>

      <h3>THIRD-PARTY WEBSITES AND SERVICES</h3>
      <p>
        We may link to third-party websites or apps, including social media
        platforms. This Notice does not apply to, and We are not responsible
        for, the privacy practices of these third-party websites or apps. Please
        read their privacy policies carefully.
      </p>
      <h3>How to delete your account ?</h3>
      <p>To completely erase your data from our platform, go to settings and click Delete Account.</p> 
      <p>You will be logged out immediately and all your data will be erased from our system within 5 days.</p>
      <h3>YOUR CALIFORNIA PRIVACY RIGHTS</h3>
      <p>
        California residents may also take advantage of the following rights:
      </p>
      <ul>
        <li>
          <strong>Access.</strong>&nbsp;You may request, up to two times each
          year, that We disclose to you the personal data (i.e., “personal
          information,” as the California Consumer Privacy Act (“CCPA”) defines
          the term) that We collect, use, and disclose about you. In response to
          a verified request, we will provide (1) the categories and specific
          pieces of personal data that we have collected, (2) the categories of
          sources from which that data is collected, (3) the business or
          commercial purpose for collecting it, (4) the categories of third
          parties with whom We shared that data, and (5) the business or
          commercial purpose for sharing that data.
        </li>
        <li>
          <strong>Delete.</strong>&nbsp;You may request that We delete any
          personal data that We have collected from or about you. Note that
          there are some reasons we will not be able to fully address your
          request, such as if We need to complete a transaction for you, to
          detect and protect against fraudulent and illegal activity, to
          exercise our rights, to use the data for solely internal purposes, or
          to comply with a legal obligation.
        </li>
      </ul>

      <p>
        To take advantage of any of these rights, or if you have any questions
        or concerns, please contact us at
        <a href="mailto:info@petloverschat.com"> info@petloverschat.com</a>. For
        verification purposes, we may request your first and last name, email
        address and any email address. You may have used when registering with
        Us; phone number including any other phone number used at registration;
        and a physical mailing address. If you are submitting on someone else’s
        behalf, we may ask for additional verification. This may include
        providing a signed letter verifying your right to make this request. We
        will not discriminate against you in response to your exercise of your
        privacy rights under the CCPA. We will respond to your access or
        deletion request within 45 days of receipt of your request, after proper
        verification, unless we need additional time, in which case we will let
        you know.
        <strong>Sale of Information.</strong>&nbsp;Under California law We are
        required to tell you if We “sell” information including information of
        Californians under 16. While We do not believe we engage in “selling”
        information, it is possible that certain of our sharing activity may be
        construed as a sale. You can opt out of this activity by visiting
        the&nbsp;Do Not Sell My Personal Information page. You can also opt out
        by emailing us at:
        <a href="mailto: info@petloverschat.com">info@petloverschat.com</a>. We do
        not sell information of Californians under 16.
      </p>

      <h3>CHANGES TO THIS&nbsp;NOTICE</h3>
      <p>
        If We change the way We handle your personal data, we will update this
        Notice. We reserve the right to make changes to our practices and this
        Notice at any time as permitted by law. You can check back to see if
        there have been any updates or changes to our Notice.
      </p>

      <h3>CONTACT</h3>
      <p>
        To contact us about this Notice and/or our privacy practices, please
        contact us at:
        <a href="mailto: info@petloverschat.com">info@petloverschat.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
